import { ORCHID, MINT, DANDELION } from 'constants/theme';

export const AGENT_PHONE_NUMBER = '866-234-8829';
export const BILLING_FREQUENCIES_ID_MONTH = '4';
export const BILLING_FREQUENCIES_ID_YEAR = '6';
export const PRODUCT_IDS = {
  HO4: '1',
  IdTheft: '5',
};

export const ID_THEFT_ENDORSEMENTS_TYPE_IDS = {
  FAMILY: '13',
  INDIVIDUAL: '12',
};

export const PACKAGE_NAMES = {
  BASIC: 'Basic',
  STANDARD: 'Standard',
  ADVANCED: 'Advanced',
  CUSTOM: 'Custom',
};

export const SHOW_ENDORSEMENTS_ON_WEBSITE = {
  REPLASEMENT_COST: 'Replacement Cost',
  EARTHQUAKE: 'Earthquake',
  WATER_BACKUP: 'Water Backup (Sewer & Drains)',
  THEF_COVERAGE: 'Theft Coverage',
};

export const ENHANCEMENT_ENDORSEMENTS = {
  SILVER: 'Enhancement Endorsement ( Silver )',
  GOLD: 'Enhancement Endorsement ( Gold )',
  PLATINUM: 'Enhancement Endorsement ( Platinum )',
};

export const PACKAGES_STATIC_CONTENT = {
  [PACKAGE_NAMES.BASIC]: {
    name: PACKAGE_NAMES.BASIC,
    description: 'Recommended if you live in a studio apartment or don’t live with roommates.',
    color: ORCHID,
    electronics: '$1,000',
    personalProperty: '$1,500',
    campingEquipment: '$7,50',
    firearm: '$1,000',
    jewelryMin: '$1,000',
    jewelryMax: '$2,000',
    tools: '$2,500',
    business: '$1,500',
    offsiteCoveragePercent: 10,
    minimumOffsiteCoverage: '$2,000',
    minimumLiability: '50000',
    minimumPersonalContent: '10000',
    minimumDeductible: '1000',
  },
  [PACKAGE_NAMES.STANDARD]: {
    name: PACKAGE_NAMES.STANDARD,
    description: 'Recommended if you live in a 1- or 2-bedroom or you live with roommates.',
    color: MINT,
    electronics: '$2,500',
    personalProperty: '$2,500',
    campingEquipment: '$1,000',
    firearm: '$2,500',
    jewelryMin: '$1,500',
    jewelryMax: '$3,000',
    tools: '$5,000',
    business: '$2,500',
    offsiteCoveragePercent: 10,
    minimumOffsiteCoverage: '$3,500',
    minimumLiability: '100000',
    minimumPersonalContent: '20000',
    minimumDeductible: '500',
  },
  [PACKAGE_NAMES.ADVANCED]: {
    name: PACKAGE_NAMES.ADVANCED,
    description: 'Recommended if you live in a 3-bedroom or you live with roommates.',
    color: DANDELION,
    electronics: '$3,000',
    offsiteCoverage: 5000,
    personalProperty: '$5,000',
    campingEquipment: '$1,200',
    firearm: '$5,000',
    jewelryMin: '$2,500',
    jewelryMax: '$7,500',
    tools: '$7,500',
    business: '$5,000',
    offsiteCoveragePercent: 20,
    minimumOffsiteCoverage: '$5,000',
    minimumLiability: '300000',
    minimumPersonalContent: '30000',
    minimumDeductible: '250',
  },
};
