/* eslint-disable */
import axios from 'axios';

const responseToData = (response) => {
    return response.data || {data: response};
};

// Following Functions will Keep in separate files for now I am just adding it here
const ENDPOINT = {
    POLICY_MANAGEMENT: 'policymanagement',
    QUOTE: 'enroll/get_quote',
    AUTH: 'auth',
    ENROLL: 'enrollment',
};

const getApiToken = () =>
    localStorage.getItem('token') ?? 'eyJ0eXAiOiJKV1QiLCJhbGciOiJFZERTQSJ9.eyJpc3MiOiJyZXNpZGVudGluc3VyZWRldi5jb20iLCJhdWQiOiJ7XCJlbnRpdHlJZFwiOjE1OTAxNTIsXCJob21lYm9keVVzZXJJZFwiOjk1NjgxOH0iLCJpYXQiOjE2OTc2OTgwODcsIm5icCI6MTY5NzY5ODEwNywiZXhwIjoxNjk3Njk5Mjg3fQ.MkWEsDuaItqlWGwhyMt49NN-g2BSPm0xM5RI_mDO8_Bv7wRl2njNpFd1K2zUh4OeO6kPXCuPHhDyw799GInDBg';

let params = '';
let BASE_URL = '';
let AWS_BASE_URL = '';

if (typeof window != 'undefined') {
    params = new URLSearchParams(window.location.search);
}

if (process.env.NODE_BRANCH !== 'live') {
    BASE_URL = `${process.env.BASE_URI}`;
    AWS_BASE_URL = `${process.env.AWS_BASE_URI}`;
} else {
    BASE_URL = 'https://api.homebody.com';
    AWS_BASE_URL = 'https://api.entrata.com/';
}
export const getBaseUrl = BASE_URL;
const axiosPost = (endPoint, method) =>
    axios
        .post(`${BASE_URL}/${endPoint}`, method, {
            headers: {
                'Authorization': `Bearer ${btoa(getApiToken())}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        })
        .then(res => res.data)
        .then(data => data);

const axiosGet = (endPoint, params = {}) => {
    return axios.get(`${BASE_URL}/${endPoint}`, {
        headers: {
            'Authorization': `Bearer ${btoa(getApiToken())}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        params,
    })
    .then(res => res.data)
    .then(data => data);
}


/* export const createAccount = data =>
  axios
    .post('/create-user-account', data, {
      headers: { 'content-type': 'application/json' }
    })
    .then(responseToData)
 */

const axiosPostAWS = (endPoint, method) =>
    axios
        .post(`${AWS_BASE_URL}${endPoint}`, method, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
        .then(responseToData)
        .then((data) => {
            return data;
        });

export const getActivePolicies = (params) =>
    axiosGet('policy_management/v1/get_active_policies', params);

export const getExpiredPolicies = (params) =>
    axiosGet('policy_management/v1/get_expired_policies', params);

export const getIdTheftProductDetails = (params) =>
    axiosGet('policy_management/v1/get_idtheft_product_details', params);

export const getPricingFromAWS = (data) =>
    axiosPostAWS('ri-quotes/v1/quotes', {
        token: getApiToken(),
        ...data,
    });

export const createAccount = (data) =>
    axiosPost(ENDPOINT.ENROLL, {
        name: 'sendEntityPassword',
        params: {
            isLiveData: 1,
            ...data,
        },
    });

export const enrollUser = (data) =>
    axios
        .post('/enroll-new-user', data, {
            headers: {'content-type': 'application/json'},
        })
        .then(responseToData);

export const login = (data) => axiosPost('auth/user_login', data);

export const changePassword = (data) =>
    axiosPost('policy_management/change_password', {
        ...data,
        token: getApiToken(),
    });

export const revertPolicyCancellation = (data) =>
    axiosPost('policy_management/revert_cancel_policy', {
        ...data,
        token: getApiToken(),
    });

export const forgotPassword = (data) => axiosPost('auth/forgot_password', data);

export const getLegalNotices = (data) =>
    axiosPost('enroll/get_legal_notices', {
        ...data,
        token: getApiToken(),
    });

export const resetPassword = (data) => axiosPost('auth/reset_password', data);

export const getPaymentTypes = (userId) =>
    axios
        .get(`/api/paymentTypes?user=${userId}`)
        .then(responseToData)
        .then((arr) => arr.reduce((obj, type) => ({...obj, [type.id]: type}), {}));

export const getPaymentMethods = (data) =>
    axiosPost('policy_management/get_payment_info', {
        ...data,
        token: getApiToken(),
    });

export const getPaymentAmount = (data) =>
    axiosPost('policy_management/get_payment_collection_details', {
        ...data,
        token: getApiToken(),
    });

export const getIdTheftPaymentAmount = (data) =>
    axiosPost('policy_management/get_idtheft_payment_collection_details', {
        ...data,
        token: getApiToken(),
    });

export const updatePaymentMethod = (data) =>
    axiosPost('policy_management/update_payment_info', {
        ...data,
        token: getApiToken(),
    });

export const addPaymentMethod = (data) =>
    axiosPost('policy_management/add_payment_info', {
        ...data,
        token: getApiToken(),
    });

export const validateEmailAddress = (data) =>
    axiosPost('enroll/check_email_exists', {
        ...data,
        token: getApiToken(),
    });

export const getPolicies = (data) =>
    axiosPost('policy_management/view_policies', {
        ...data,
        token: getApiToken(),
    });

export const getPetHealthInsuranceLink = (data) =>
    axiosPost('policy_management/get_pet_health_insurance_link', {
        ...data,
        token: getApiToken(),
    });

export const downloadPolicy = (data) =>
    axiosPost('policy_management/download_policy', {
        ...data,
        token: getApiToken(),
    });

export const policyCancellation = (data) =>
    axiosPost('policy_management/cancel_policy', {
        ...data,
        token: getApiToken(),
    });

export const getPolicyPaymentStatus = (data) =>
    axiosPost('enroll/check_policy_payment_status', {
        ...data,
        token: getApiToken(),
    });

export const validateBlacklistAccount = (data) =>
    axiosPost('enroll/validate_account', {
        ...data,
        token: getApiToken(),
    });

export const validateRoutingNumber = (data) =>
    axiosPost('enroll/validate_routing_number', {
        ...data,
        token: getApiToken(),
    });

export const getPolicyTypes = (data) =>
    axios
        .get('/policy-types', data, {
            headers: {'content-type': 'application/json'},
        })
        .then(responseToData);

export const getPricing = (data) =>
    axiosPost(`enroll/get_quote`, {
        token: getApiToken(),
        ...data,
    });

export const getProperties = (data) =>
    axiosPost('enroll/get_properties', {
        ...data,
        token: getApiToken(),
    });

export const getToken = (data) =>
    axiosPost('auth/generate_token', {
        ...data,
        token: getApiToken(),
    });

export const consumerRequestSubmit = (data) =>
    axiosPost('site_map/submit_cr_from', {
        ...data,
        token: getApiToken(),
    });

export const retrieveQuote = (data) =>
    axios
        .get('/api/savedQuotes', data, {
            headers: {'content-type': 'application/json'},
        })
        .then(responseToData);

export const validateAddress = (data) =>
    axiosPost('enroll/validate_address', {
        ...data,
        token: getApiToken(),
    });

export const insertQuote = (data) =>
    axiosPost('enroll/create_policy', {
        ...data,
        token: getApiToken(),
    });

export const saveQuote = (data) =>
    axiosPost('enroll/email_quote', {
        ...data,
        token: getApiToken(),
    });

export const enrollPolicy = (data) =>
    axiosPost('enroll/enroll_policy', {
        ...data,
        token: getApiToken(),
    });

export const addPolicy = (data) =>
    axiosPost('enroll/add_policy', {
        ...data,
        token: getApiToken(),
    });

export const editCoverage = (data) =>
    axiosPost('policy_management/edit_policy_coverage', {
        ...data,
        token: getApiToken(),
    });

export const getPolicyCoverage = (data) =>
    axiosPost('policy_management/get_policy_coverage', {
        ...data,
        token: getApiToken(),
    });

export const updatePolicyAddress = (data) =>
    axiosPost('policy_management/update_policy_address', {
        ...data,
        token: getApiToken(),
    });

export const updatePolicyMailingAddress = (data) =>
    axiosPost('policy_management/update_policy_mailing_address', {
        ...data,
        token: getApiToken(),
    });

export const updatePolicyFrequency = (data) =>
    axiosPost('policy_management/update_policy_frequency', {
        ...data,
        token: getApiToken(),
    });

export const requestEmailAlreadyExist = (email) =>
    axiosPost(ENDPOINT.QUOTE, {
        name: 'checkUniqueQuoteEmail',
        params: {
            emailAddress: email,
        },
    });

// New Payment APIs

export const getUpcomingPayments = (data) =>
    axiosPost('policy_management/get_upcoming_payment', {
        ...data,
        token: getApiToken(),
    });

export const getPaymentHistory = (data) =>
    axiosPost('policy_management/get_payment_history', {
        ...data,
        token: getApiToken(),
    });

export const getPolicyDetails = (data) =>
    axiosPost('policy_management/view_policy_details', {
        ...data,
        token: getApiToken(),
    });

export const insertNote = (data) =>
    axiosPost('policy_management/add_policy_note', {
        ...data,
        token: getApiToken(),
    });

export const addInterestedParty = (data) =>
    axiosPost('policy_management/create_additional_interest', {
        ...data,
        token: getApiToken(),
    });

export const editInterestedParty = (data) =>
    axiosPost('policy_management/create_additional_interest', {
        ...data,
        token: getApiToken(),
    });

export const removeInterestedParty = (data) =>
    axiosPost('policy_management/delete_additional_interest', {
        ...data,
        token: getApiToken(),
    });

// API to update profile details
export const updateProfile = (data) =>
    axiosPost('policy_management/update_profile_information', {
        ...data,
        token: getApiToken(),
    });

// API to enroll policy after login
export const createPolicyAfterLogin = (data) =>
    axiosPost('policy_management/create_policy', {
        ...data,
        token: getApiToken(),
    });

export const enrollPolicyAfterLogin = (data) =>
    axiosPost('policy_management/enroll_policy', {
        ...data,
        token: getApiToken(),
    });

export const getPricingAfterLogin = (data) =>
    axiosPost('policy_management/get_quote', {
        token: getApiToken(),
        ...data,
    });

export const generateUniqueAgentCode = (data) =>
    axiosPost('external/phone_payment_reference/get_unique_agent_code', {
        token: getApiToken(),
        ...data,
    });

export const validatePaymentCompletion = (data) =>
    axiosPost('external/phone_payment_reference/get_completed_phone_payment_references', {
        token: getApiToken(),
        ...data,
    });
