/* eslint-disable */
import { css } from '@emotion/core';

//colors for homebody
export const EMERALD = '#004b37';
export const MINT = '#70fc8d';
export const ASH = '#e6e6e6';
export const BLACK = '#000000';
export const VIOLET = '#58355e';
export const TANGERINE = '#ff8553';
export const MRDN_TEXT_DARK = '#292929';
export const MRDN_GRAY = '#EBEBEB';
export const MRDN_GRAY_800 = '#525252';
export const MRDN_WHITE = ' #FDFDFD';
export const MRDN_BLUE = ' #4296DA';
export const MRDN_DARK_BLUE = '#1578C9';
export const LIGHT_GREEN = '#F4F8F6';
export const MINT_GREEN = '#EBF9EE';

// fonts
export const ARIAL = 'Arial, Helvetica, sans-serif';
export const ICONS = 'ri-icons';
export const BROWN_LIGHT = 'BrownEntrataLight';
export const BROWN_REGULAR = 'BrownEntrataRegular';
export const BROWN_ITALIC = 'BrownEntrataItalic';
export const BROWN_BOLD = 'BrownEntrataBold';
export const SOHNE_BOLD = 'SoehneKraftig';

//font-family
export const ALGEBRA_DISPLAY = 'Algebra Display';

// colors
export const RED = '#e42127';
export const LIGHT_RED = '#f4a4a7';
export const HIGHLIGHT_RED = '#feefef';

export const BLUE = '#587C94';
export const DARK_BLUE = '#354E62';
export const BASEBLACK = '#302F2F';
export const MEDIUM_BLUE = '#91A4B4';
export const HIGHLIGHT_BLUE = '#f0f3f5';
export const MIXHIGHLIGHTBLUEWHITE65 = '#f5f7f9';
export const LINK_BLUE = '#4a90e2';
export const BG_LIGHT_SKYBLUE = '#f9fcff';
export const BG_LIGHT_GRAY = '#dee7ef';
export const BGDARKGRAY = '#4E4D4D';

export const BG_BLUE = '#476882';
export const BG_GRAY = '#979797';

export const WHITE = '#fff';
export const YELLOW = '#fdf9c2';
export const GRAY_CHATEAU = '#9EA8B0';

export const GRAY = '#a6a6a6';
export const LIGHT_GRAY = '#e6e7e8';
export const BORDER_GRAY = '#ccc';
export const MEDIUM_GRAY = '#999999';
export const SLIVER_GRAY = '#B8B8B8';

export const TEXT_DARK = '#222';
export const TEXT_MEDIUM = '#58595b';
export const TEXT_LIGHT = '#808184';
export const TEXT_ORANGE = '#ff8400';
export const LIGHT_ORANGE = '';

export const GREEN = '#39B54A';
export const TEXT_GREEN = '#2c8c3a';
export const BG_LIGHT_GREEN = '#e7f7e9';

export const TEXT_YELLOW = '#ff8400';
export const BG_LIGHT_YELLOW = '#fefbd8';
export const LIGHT_PINK = '#f5dbdc';
export const LIGHT_BLUE = '#7699b5';

export const TEXT_BLUE = '#145ea0';
export const LIGHT_TEXT_BLUE = '#214e76';
export const BG_LIGHT_BLUE = '#e5f4ff';
export const MEDIUM_PINK = '#DFE2E5';
export const M_GRAY_500 = '#B8B8B8';
export const VERY_LIGHT_GRAY = '#e0e0e0'; // meridian gray88
export const ORCHID = '#FFCCD5';
export const DANDELION = '#FFD752';
export const ORCHID_LIGHT = '#F4CCCF';
export const GREEN_LIGHT = '#EBF1EF';

// breakpoints
export const MQ_320 = '20em';
export const MQ_500 = '31.25em';
export const MQ_549 = '34.3125em';
export const MQ_550 = '34.375em';
export const MQ_575 = '35.9375em';
export const MQ_576 = '36em';
export const MQ_600 = '37.5em';
export const MQ_705 = '44.0625em';
export const MQ_767 = '47.9375em';
export const MQ_768 = '48em';
export const MQ_850 = '53.125em';
export const MQ_920 = '57.5em';
export const MQ_959 = '59.938em';
export const MQ_960 = '60em';
export const MQ_961 = '60.0625em';
export const MQ_1285 = '80.3125em';
export const MQ_1286 = '80.375em';
export const MQ_1550 = '96.875em';

// Sizing and spacing
// export const SIZE_8 = '8px'
// export const SIZE_16 = '16px'
// export const SIZE_24 = '24px'
// export const SIZE_32 = '32px'
// export const SIZE_40 = '40px'
// export const SIZE_48 = '48px'
// export const SIZE_56 = '56px'
// export const SIZE_64 = '64px'

// Function that multiplies the Base Grid value and returns appropriate pixel string

export const fontSize = (size) => css`
  font-size: ${size / 16}rem;
`;

export const contentWidth = css`
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 32px;
  padding-right: 32px;

  @media only screen and (min-width: ${MQ_768}) {
    padding-left: 64px;
    padding-right: 64px;
  }

  @media only screen and (max-width: ${MQ_320}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const contentCenter = css`
  margin: 0;
`;

export const content_pad_b = css`
  padding-bottom: 96px;

  @media only screen and (min-width: ${MQ_768}) {
    padding-bottom: 112px;
  }

  @media only screen and (max-width: ${MQ_320}) {
    padding-bottom: 40px;
  }
`;

export const content_pad_t = css`
  padding-top: 40px;

  @media only screen and (min-width: ${MQ_768}) {
    padding-top: 64px;
  }
`;

export const homeBodyTheme = {
  primary: {
    base: '#70FC8D',
    dark: '',
    medium: '#FF8553',
    light: '#58355e',
  },
  secondary: {
    base: '#004B37',
    dark: '#004B37',
    medium: '#91A4B4',
    light: '#EBF1EF',
  },
  tertiary: {
    base: '#a6a6a6',
    dark: '',
    medium: '#004B37',
    light: '#FDFDFD', // meridian white
  },
  success: { base: '#39B54A' },
  error: { base: '#e42127' },
  warning: { base: '#fdf9c2' },
  info: { base: '#004B37' },
  text: {
    dark: '#292929', // meridian black
    medium: '#58595b',
    light: '#999',
  },
  fonts: {
    light: 'InterLight',
    regular: 'InterRegular',
    italic: 'BrownEntrataItalic, Helvetica Neue, Helvetica, Arial, sans-serif',
    bold: 'InterBold, Helvetica Neue, Helvetica, Arial, sans-serif',
    body: 'Arial, sans-serif',
    algebraLight: 'AlgebraDisplayLight',
    soehneKraftig: 'SoehneKraftig',
    soehneBuch: 'SoehneBuch',
  },
  fields: {
    border: '#ccc',
    focus: '#004B37',
  },
  gray: {
    medium: '#7a7a7a', // meridian gray 700
    base: '#525252', // meridian gray
    light: '#e6e6e6',
    highlight: '#f2f2f2',
  },
  alerts: {
    warning: { medium: '', base: '#135791', light: '#DFEEFA', highlight: '' }, // meridian-blue-100
    success: { medium: '', base: '#324D35', light: '#E6EFE7', highlight: '' }, // meridian-green-100
    error: { medium: '', base: '#851D23', light: '#F7DDDE', highlight: '' }, // meridian-red-100
    info: { medium: '', base: '#AD7C00', light: '#FFF6E0', highlight: '' }, // meridian-yellow-900
  },
  white: '#ffffff',
};
