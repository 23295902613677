/* eslint-disable */
import Api from 'api';
import { call, put, takeLatest, select, all } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { receiveNotes } from './notes';
import {
  USER_LOGOUT,
  validateToken,
  updateIdTheftPolicyPaymentFailed,
} from './user';
import { setGlobalLoading, unsetGlobalLoading } from './globalLoading';
import { setLoading, unsetLoading } from 'redux/modules/loading';
import { receivePaymentHistory } from './payments';
import { addError } from './errors';
import { isAfter, isEqual } from 'date-fns';
import billingFrequencies from 'constants/billingFrequencies';
import { parsePricingData, parseQuoteData, RECEIVE_PRICING } from './pricing';
import { updateEnrollPolicyError } from './enrollment';
import { RECEIVE_LOCATION_DATA } from './properties';
import { RECEIVE_AVAILABLE_PRODUCTS } from './availableProducts';
import { INIT_QUOTE } from './quote';
import {
  getPricingSelector,
  updatePremium,
  calculatePremium,
  toggleLiabilityOnly,
  toggleEnhancementEndorsement,
} from './quoteValues';
import { setProperty, setLeadSourceType } from './getQuote';
import { insertQuoteSelector } from './savedQuote';
import createRekeyedObject from 'utils/createRekeyedObject';
import { setLegalNoticesData } from './legalNotices';

// reducer
const initialState = {
  activePolicies: {},
  totalRecords: 0,
  expiredPolicies: {},
  policyDetails: {},
  interestedParties: [],
  additionalInsureds: [],
  policyHistory: [],
  insureds: [],
  isInsuredUpdated: false,
  isFutureCoverageCreated: false,
  petHealthInsuranceDetail: {},
  accountDetail: [],
  residentSecureSsoUrl: null,
  idTheftProductDetails: {}
};

// action types
export const RECEIVED_ACTIVE_POLICIES = 'ri/policies/RECEIVED_ACTIVE_POLICIES';
const REQUEST_ACTIVE_POLICIES = 'ri/policies/REQUEST_ACTIVE_POLICIES';
const REQUEST_EXPIRED_POLICIES = 'ri/policies/REQUEST_EXPIRED_POLICIES';
export const RECEIVED_EXPIRED_POLICIES = 'ri/policies/RECEIVED_EXPIRED_POLICIES';
const REQUEST_IDTHEFT_PRODUCT_DETAILS = 'ri/policies/REQUEST_IDTHEFT_PRODUCT_DETAILS';
const ADD_INTERESTED_PARTY = 'ri/policies/ADD_INTERESTED_PARTY';
const INTERESTED_PARTY_ADDED = 'ri/policies/INTERESTED_PARTY_ADDED';
const EDIT_INTERESTED_PARTY = 'ri/policies/EDIT_INTERESTED_PARTY';
export const INTERESTED_PARTY_UPDATED = 'ri/policies/INTERESTED_PARTY_UPDATED';
const REMOVE_INTERESTED_PARTY = 'ri/policies/REMOVE_INTERESTED_PARTY';
const INTERESTED_PARTY_DELETED = 'ri/policies/INTERESTED_PARTY_DELETED';
export const REQUEST_POLICY_DETAILS = 'ri/policies/REQUEST_POLICY_DETAILS';
export const RECEIVE_POLICY_DETAILS = 'ri/policies/RECEIVE_POLICY_DETAILS';
const ADD_POLICY = 'ri/policies/ADD_POLICY';
const REQUEST_EDIT_IDTHEFT_POLICY = 'ri/policies/REQUEST_EDIT_IDTHEFT_POLICY';
const UPDATE_POLICY_ADDRESS = 'ri/policies/UPDATE_POLICY_ADDRESS';
export const REQUEST_POLICY_MAILING_ADDRESS =
  'ri/policies/REQUEST_POLICY_MAILING_ADDRESS';
export const RECEIVED_POLICY_MAILING_ADDRESS =
  'ri/policies/RECEIVED_POLICY_MAILING_ADDRESS';
export const POLICY_ADDRESS_UPDATED = 'ri/policies/POLICY_ADDRESS_UPDATED';
const UPDATE_POLICY_FREQUENCY = 'ri/policies/UPDATE_POLICY_FREQUENCY';
export const POLICY_FREQUENCY_UPDATED = 'ri/policies/POLICY_FREQUENCY_UPDATED';
const POLICY_CANCELLATION_REQUEST = 'ri/policies/POLICY_CANCELLATION_REQUEST';
export const POLICY_CANCELLATION_RESPONSE =
  'ri/policies/POLICY_CANCELLATION_RESPONSE';
const POLICY_DOWNLOAD_REQUEST = 'ri/policies/POLICY_DOWNLOAD_REQUEST';
const POLICY_REVERT_CANCELLATION_REQUEST =
  'ri/policies/POLICY_REVERT_CANCELLATION_REQUEST';
const SET_INSURANCE_POLICY = 'ri/policies/SET_INSURANCE_POLICY';
const REQUEST_EDIT_COVERAGE = 'ri/policies/REQUEST_EDIT_COVERAGE';
const REQUEST_POLICY_COVERAGE = 'ri/policies/REQUEST_POLICY_COVERAGE';
export const ADD_ADDITIONAL_INSUREDS = 'ri/policies/ADD_ADDITIONAL_INSUREDS';
export const CLEAN_POLICIES = 'ri/policies/CLEAN_POLICIES';
export const POLICY_PAYMENT_FAILED_UPDATE =
  'ri/policies/POLICY_PAYMENT_FAILED_UPDATE';
const GET_PET_HEALTH_INSURANCE_LINK =
  'ri/policies/GET_PET_HEALTH_INSURANCE_LINK';
const RECEIVED_RESIDENT_SECURE_SSO_URL = 'ri/policies/RECEIVED_RESIDENT_SECURE_SSO_URL';
const REMOVE_RESIDENT_SECURE_SSO_URL = 'ri/policies/REMOVE_RESIDENT_SECURE_SSO_URL';
export const RECEIVED_ID_THEFT_PRODUCT_DETAILS = 'ri/policies/RECEIVED_ID_THEFT_PRODUCT_DETAILS';

// reducer
export default (state = initialState, action) => {

  const handleClearActivePolicies = () => {
    const { pageNumber } = action.payload;
    return {
      ...state,
      ...pageNumber === 1 && {
        totalRecords: 0,
        activePolicies: {},
      },
    };
  }

  const handleClearExpiredPolicies = () => {
    const { pageNumber } = action.payload;
    return {
      ...state,
      ...pageNumber === 1 && {
        totalRecords: 0,
        expiredPolicies: {},
      },
    };
  }

  const handleReceiveActivePolicies = () => {
    const { totalRecords, autoPaymentChargeMessage, policies, pageNumber } = action.payload;
    const latestActivePolicies = createRekeyedObject(policies, 'id');

    return {
      ...state,
      autoPaymentChargeMessage,
      totalRecords: pageNumber === 1 ? totalRecords : state.totalRecords,
      activePolicies: {
        ...state.activePolicies,
        ...latestActivePolicies,
      },
    };
  };

  const handleReceiveExpiredPolicies = () => {
    const { totalRecords, policies, pageNumber } = action.payload;
    const latestExpiredPolicies = createRekeyedObject(policies, 'id');

    return {
      ...state,
      totalRecords: pageNumber === 1 ? totalRecords : state.totalRecords,
      expiredPolicies: {
        ...state.expiredPolicies,
        ...latestExpiredPolicies,
      },
    };
  };

  const handleIdTheftProductDetails = () => {
    const {
      idTheftProductDetails: { idTheftEndorsements, isArrayEnrolmentEnabled },
      petHealthInsuranceDetail,
    } = action.payload;
    const idTheftRekeyedEndorsements = createRekeyedObject(idTheftEndorsements, 'endorsementTypeId')

    return {
      ...state,
      petHealthInsuranceDetail,
      idTheftProductDetails: {
        ...action.payload.idTheftProductDetails,
        idTheftEndorsements: idTheftRekeyedEndorsements,
        isArrayEnrolmentEnabled: isArrayEnrolmentEnabled,
      }
    };
  };

  const handlePolicyCancellationResponse = () => {
    const { endDate, isFutureCoverage, insurancePolicyId } = action.payload;
    const {policyDetails, activePolicies } = state;

    // Cancel policy from active policy page
    if(Object.keys(activePolicies).length && activePolicies[insurancePolicyId]) {
      const policyToUpdate = activePolicies[insurancePolicyId];
      const isExpired = isAfter(policyToUpdate.effectiveDate, endDate) || isEqual(policyToUpdate.effectiveDate, endDate);

      if(isExpired) {
        // Deleting the object key and its associated value from activePolicies object if the policy is expired
        delete activePolicies[insurancePolicyId];
      } else {
        policyToUpdate.endDate = endDate;
        activePolicies[insurancePolicyId] = policyToUpdate;
      }
    }

    const covarageType = isFutureCoverage ? 'futureCoverage' : 'currentCoverage';

    // Cancel policy from policy details page
    if(Object.keys(policyDetails).length && policyDetails[covarageType]) {
      policyDetails[covarageType].endDate = endDate;
    }

    return {
      ...state,
      activePolicies: {
        ...activePolicies
      },
      policyDetails: {
        ...policyDetails
      }
    };
  };

  const handleAdditionalInsureds = () => {
    const {
      additionalInsureds = [],
      insureds = [],
      isInsuredUpdated = false,
    } = action.payload;
    return {
      ...state,
      additionalInsureds,
      ...(insureds.length && {
        insureds,
      }),
      isInsuredUpdated,
    };
  };

  const handleInterestedPartyUpdated = () => {
    const updatedParty = action.payload;
    const interestedParties = state.interestedParties.reduce(
      (parties, party) => [
        ...parties,
        {...(party.id == updatedParty.oldId ? updatedParty : party)},
      ],
      []
    );

    return {
      ...state,
      interestedParties,
    };
  };

  const handleReceivePolicyDetails = () => {
    const {
      interestedParties: interestedPartiesArr,
      policyHistory,
      currentCoverage,
      futureCoverage = {},
      policyHistoryMaxDate,
      policyHistoryMinDate,
      paymentHistoryMaxDate,
      paymentHistoryMinDate,
      isAgent,
      showChangeCancellationDates,
      isShowInterestedParties,
      policyDetails,
    } = action.payload;

    return {
      ...state,
      interestedParties: interestedPartiesArr,
      isFutureCoverageCreated: false,
      policyHistory,
      policyDetails: {
        ...policyDetails,
        currentCoverage,
        futureCoverage,
        policyHistoryMaxDate,
        policyHistoryMinDate,
        paymentHistoryMaxDate,
        paymentHistoryMinDate,
        isAgent,
        showChangeCancellationDates,
        isShowInterestedParties,
      },
    };
  };

  const handlePolicyAddressUpdated = () => {
    const {policyDetails, activePolicies} = state;
    const {
      streetAddress,
      isFutureCoverage,
      isFutureCoverageCreated,
      city,
      postalCode,
      stateCode,
      insurancePolicyId,
    } = action.payload;

    const policyCoverage = isFutureCoverage
      ? 'futureCoverage'
      : 'currentCoverage';

    if(Object.keys(activePolicies).length && activePolicies[insurancePolicyId]) {
      const policyToUpdate = activePolicies[insurancePolicyId];

      policyToUpdate.insuredAddress = {
        ...policyToUpdate.insuredAddress,
        street: streetAddress,
        city: city,
        zip: postalCode,
        state: stateCode,
      };

      activePolicies[insurancePolicyId] = policyToUpdate;
    }

    return {
      ...state,
      isFutureCoverageCreated,
      activePolicies: {
        ...activePolicies
      },
      policyDetails: {
        ...policyDetails,
        [policyCoverage]: {
          ...policyDetails[policyCoverage],
          insuredAddress: {
            ...policyDetails[policyCoverage].insuredAddress,
            street: streetAddress,
            city: city,
            zip: postalCode,
            state: stateCode,
          },
        },
      },
    };
  };

  const handleReceivedPolicyMailingAddress = () => {
    let {policyDetails, activePolicies} = state;
    const {
      mailingStreetLine1,
      mailingStateCode,
      mailingCity,
      mailingPostalCode,
      coverageType,
      insurancePolicyId,
    } = action.payload;

    if(Object.keys(activePolicies).length && activePolicies[insurancePolicyId]) {
      const policyToUpdate = activePolicies[insurancePolicyId];

      policyToUpdate.mailingAddress = {
        ...policyToUpdate.mailingAddress,
        mailingStreetLine1,
        mailingStateCode,
        mailingCity,
        mailingPostalCode,
      };

      activePolicies[insurancePolicyId] = policyToUpdate;
    }

    return {
      ...state,
      activePolicies: {
        ...activePolicies
      },
      policyDetails: {
        ...policyDetails,
        [coverageType]: {
          ...policyDetails[coverageType],
          mailingAddress: {
            ...policyDetails[coverageType]?.mailingAddress,
            mailingStreetLine1,
            mailingStateCode,
            mailingCity,
            mailingPostalCode,
          },
        },
      }
    };
  };

  const handlePolicyFrequencyUpdated = () => {
    const { frequencyId, premiumAmount, insurancePolicyId } = action.payload;
    return {
      ...state,
      activePolicies: {
        ...state['activePolicies'],
        [insurancePolicyId]: {
          ...state['activePolicies'][insurancePolicyId],
          billingFrequency: frequencyId,
          premiumAmount: premiumAmount,
        },
      },
    };
  };

  const handlePolicyPaymentFailedUpdate = () => {
    const { isPaymentFailed, insurancePolicyId } = action.payload;
    return {
      ...state,
      activePolicies: {
        ...state['activePolicies'],
        ...(state['activePolicies'][insurancePolicyId] && {
          [insurancePolicyId]: {
            ...state['activePolicies'][insurancePolicyId],
            isPaymentFailed,
          },
        }),
      },
    };
  };

  switch (action.type) {
    case REQUEST_ACTIVE_POLICIES:
      return handleClearActivePolicies();
    case REQUEST_EXPIRED_POLICIES:
      return handleClearExpiredPolicies();
    case RECEIVED_ACTIVE_POLICIES:
      return handleReceiveActivePolicies();
    case RECEIVED_ID_THEFT_PRODUCT_DETAILS:
      return handleIdTheftProductDetails();
    case RECEIVED_EXPIRED_POLICIES:
      return handleReceiveExpiredPolicies();
    case POLICY_CANCELLATION_RESPONSE:
      return handlePolicyCancellationResponse();
    case ADD_ADDITIONAL_INSUREDS:
      return handleAdditionalInsureds();
    case SET_INSURANCE_POLICY:
      return {
        ...state,
        insurancePolicyId: action.payload.insurancePolicyId,
      };
    case INTERESTED_PARTY_ADDED:
      return {
        ...state,
        interestedParties: [...state.interestedParties, {...action.payload}],
      };
    case INTERESTED_PARTY_UPDATED:
      return handleInterestedPartyUpdated();
    case INTERESTED_PARTY_DELETED:
      return {
        ...state,
        interestedParties: [
          ...state.interestedParties.filter(
            ({ id }) => id != action.payload.id
          ),
        ],
      };
    case REQUEST_POLICY_DETAILS:
      return {
        ...state,
        policyDetails: {},
      };
    case RECEIVE_POLICY_DETAILS:
      return handleReceivePolicyDetails();
    case POLICY_ADDRESS_UPDATED:
      return handlePolicyAddressUpdated();
    case RECEIVED_POLICY_MAILING_ADDRESS:
      return handleReceivedPolicyMailingAddress();
    case POLICY_FREQUENCY_UPDATED:
      return handlePolicyFrequencyUpdated();
    case POLICY_PAYMENT_FAILED_UPDATE:
      return handlePolicyPaymentFailedUpdate();
    case REMOVE_RESIDENT_SECURE_SSO_URL:
      return {
        ...state,
        residentSecureSsoUrl: null
      }
    case RECEIVED_RESIDENT_SECURE_SSO_URL:
      return {
        ...state,
        residentSecureSsoUrl: action.payload
      }
    case CLEAN_POLICIES:
      return {
        ...state,
        activePolicies: {},
        expiredPolicies: {},
      };
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

// action creators
export const requestActivePolicies = payload => ({
  type: REQUEST_ACTIVE_POLICIES,
  payload,
});

const receivedActivePolicies = payload => ({
  type: RECEIVED_ACTIVE_POLICIES,
  payload,
});

export const requestExpiredPolicies = payload => ({
  type: REQUEST_EXPIRED_POLICIES,
  payload,
});

const receivedExpiredPolicies = payload => ({
  type: RECEIVED_EXPIRED_POLICIES,
  payload,
});

export const requestIdTheftProductDetails = payload => ({
  type: REQUEST_IDTHEFT_PRODUCT_DETAILS,
  payload,
});

export const receivedIdTheftProductDetails = payload => ({
  type: RECEIVED_ID_THEFT_PRODUCT_DETAILS,
  payload,
});

export const getPetHealthInsuranceLink = payload => ({
  type: GET_PET_HEALTH_INSURANCE_LINK,
  payload,
});

export const cancelPolicy = payload => ({
  type: POLICY_CANCELLATION_REQUEST,
  payload,
});

export const addInterestedParty = payload => ({
  type: ADD_INTERESTED_PARTY,
  payload,
});

export const updateCancelPolicy = payload => ({
  type: POLICY_CANCELLATION_RESPONSE,
  payload,
});

export const setInsurancePolicy = payload => ({
  type: SET_INSURANCE_POLICY,
  payload,
});

export const requestPolicyCoverage = payload => ({
  type: REQUEST_POLICY_COVERAGE,
  payload,
});

export const requestEditPolicyCoverage = payload => ({
  type: REQUEST_EDIT_COVERAGE,
  payload,
});

export const addAdditionalInsureds = payload => ({
  type: ADD_ADDITIONAL_INSUREDS,
  payload,
});

export const editInterestedParty = payload => ({
  type: EDIT_INTERESTED_PARTY,
  payload,
});

export const removeInterestedParty = payload => ({
  type: REMOVE_INTERESTED_PARTY,
  payload,
});

export const requestPolicyDetails = payload => ({
  type: REQUEST_POLICY_DETAILS,
  payload,
});

export const receivePolicyDetail = payload => ({
  type: RECEIVE_POLICY_DETAILS,
  payload,
});

export const addPolicy = payload => ({
  type: ADD_POLICY,
  payload,
});

export const editIdTheftPolicy = payload => ({
  type: REQUEST_EDIT_IDTHEFT_POLICY,
  payload,
});

export const updatePolicyAddress = payload => ({
  type: UPDATE_POLICY_ADDRESS,
  payload,
});

export const updatePolicyMailingAddress = payload => ({
  type: REQUEST_POLICY_MAILING_ADDRESS,
  payload,
});

export const updatePolicyFrequency = payload => ({
  type: UPDATE_POLICY_FREQUENCY,
  payload,
});

export const downloadPolicyRequest = payload => ({
  type: POLICY_DOWNLOAD_REQUEST,
  payload,
});

export const revertCancelPolicyRequest = payload => ({
  type: POLICY_REVERT_CANCELLATION_REQUEST,
  payload,
});

const receivedResidentSecureSsoUrl = payload => ({
  type: RECEIVED_RESIDENT_SECURE_SSO_URL,
  payload
})

export const removeResidentSecureSsoUrl = payload => ({
  type: REMOVE_RESIDENT_SECURE_SSO_URL,
  payload
})

// selectors
export const AddPolicySelector = ({
  user: { entityId, email },
  getQuote: { user, employee, leadSourceType },
  quoteValues: { billingFrequency },
}) => ({
  userId: user,
  entityId: entityId,
  employeeId: employee.id,
  leadSourceTypeId: leadSourceType,
  propertyId: null,
  cid: null,
  emailAddress: email,
  carrierClassificationTypeId: 1,
  insuranceCarrierId: 77,
  policyFrequencyId: billingFrequency,
  effectiveDate: null,
  idTheftPremiumAmount: 6.99,
  idTheftAdminFee: 3.99,
  endorsements: {
    899: {
      id: 899,
      productId: 5,
      name: 'ID Theft - Family',
      premiumAmount: 9.99,
    },
  },
});

// sagas
function* getActivePolicies({payload}) {
  yield put(setGlobalLoading(`Loading Active Policies...`));
  try {
    const { responseCode, result } = yield call(Api.getActivePolicies, payload);

    if (responseCode === 200) {
      yield put(receivedActivePolicies({...result, pageNumber: payload.pageNumber}));
      if(result?.policies?.length) {
        const {insuranceCarrierId, propertyAddress: {state}} = result.policies[0];
        yield put(
          setLegalNoticesData({ stateCode: state, insuranceCarrierId })
        );
      }
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
  } catch (error) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(error);
    console.log('failed', error.toString());
  }
  yield put(unsetGlobalLoading());
}

function* getExpiredPolicies({payload}) {
  yield put(setGlobalLoading(`Loading Expired Policies...`));
  try {
    const { responseCode, result } = yield call(Api.getExpiredPolicies, payload);
    if (responseCode === 200) {
      yield put(receivedExpiredPolicies({...result, pageNumber: payload.pageNumber}));
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
  } catch (error) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(error);
    console.log('failed', error.toString());
  }
  yield put(unsetGlobalLoading());
}

function* getIdTheftProductDetails({ payload }) {
  try {
    const { responseCode, result } = yield call(Api.getIdTheftProductDetails, payload);

    if (responseCode === 200) {
      yield put(receivedIdTheftProductDetails(result));
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
  } catch (error) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(error);
    console.log('failed', error.toString());
  }
}

function* watchGetActivePolicies() {
  yield takeLatest(REQUEST_ACTIVE_POLICIES, getActivePolicies);
}

function* watchGetExpiredPolicies() {
  yield takeLatest(REQUEST_EXPIRED_POLICIES, getExpiredPolicies);
}

function* watchGetIdTheftProductDetails() {
  yield takeLatest(REQUEST_IDTHEFT_PRODUCT_DETAILS, getIdTheftProductDetails);
}

function* getPolicyDetails({ payload }) {
  try {
    yield put(setGlobalLoading(`Loading policy details...`));
    const response = yield call(Api.getPolicyDetails, payload);
    if (response.responseCode === 200) {
      const { notes = [], paymentHistory, residentSecureSsoUrl = '' } = response.result;
      yield put(receivePolicyDetail(response.result));
      yield put(receiveNotes(notes));
      yield put(receivePaymentHistory({ paymentHistory }));
      yield put(receivedResidentSecureSsoUrl(residentSecureSsoUrl));
    } else if (response.responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
    yield put(unsetGlobalLoading());
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.log('failed', err.toString());
    yield put(unsetGlobalLoading());
  }
}

function* watchPolicyCancellation() {
  yield takeLatest(POLICY_CANCELLATION_REQUEST, policyCancellation);
}

function* policyCancellation({ payload }) {
  try {
    const response = yield call(Api.policyCancellation, payload);
    if (response.responseCode === 200) {
      yield put(
        addError({
          key: 'policyCancellationSuccess',
          message: 'Policy canceled successfully',
        })
      );
    } else if (response.responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    } else {
      yield put(
        addError({
          key: 'policyCancellation',
          message: response.message,
        })
      );
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    yield put(
      addError({
        key: 'policyCancellation',
        message: err.toString(),
      })
    );
  }
}

function* watchGetPolicyDetails() {
  yield takeLatest(REQUEST_POLICY_DETAILS, getPolicyDetails);
}

function* addPolicyWorker({ payload }) {
  try {
    yield put(
      updateIdTheftPolicyPaymentFailed({
        isIdTheftPaymentFailed: false,
        idTheftPaymentFailedReason: '',
      })
    );

    let addPolicyData = yield select(AddPolicySelector);
    const {
      endorsements,
      idTheftProductDetails: {
        idTheftEffectiveDate,
        frequencyId,
        propertyId,
        cid,
        accountDetail: {
          paymentTypeId,
          isAllowedRealTimePayment,
        }
      },
      ssnData,
    } = payload;

    const response = yield call(Api.addPolicy, {
      ...addPolicyData,
      propertyId,
      cid,
      effectiveDate: idTheftEffectiveDate,
      isAllowedRealTimePayment,
      paymentTypeId,
      idTheftPremiumAmount: endorsements.premiumAmount,
      idTheftAdminFee: endorsements.adminFee,
      policyFrequencyId: frequencyId,
      endorsements: {
        [endorsements.id]: endorsements,
      },
      customerDetails: {
        ssnData: ssnData,
      }
    });
    if (response.responseCode == 200) {
      yield put(
        addError({
          key: 'addIdtheftPolicy',
          message: isAllowedRealTimePayment
            ? response?.message
            : 'Policy added successfully.',
        })
      );
    } else if (response.responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    } else {
      yield put(
        updateIdTheftPolicyPaymentFailed({
          isIdTheftPaymentFailed: response?.result.isIdTheftPaymentFailed,
          idTheftPaymentFailedReason: '',
        })
      );
      yield put(
        updateEnrollPolicyError({ enrollPolicyError: response?.message })
      );
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    yield put(
      addError({
        key: 'addIdtheftPolicyError',
        message: err.toString(),
      })
    );
  }
}

function* watchAddPolicy() {
  yield takeLatest(ADD_POLICY, addPolicyWorker);
}

function* workerEditIdTheftPolicy({ payload }) {
  try {
    const { responseCode, message } = yield call(Api.editCoverage, payload);
    if (responseCode == 200) {
      yield put(
        addError({
          key: 'editPolicySuccess',
          message,
        })
      );
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    yield put(
      addError({
        key: 'editPolicyError',
        message: err.message,
      })
    );
  }
}

function* watchEditIdTheftPolicy() {
  yield takeLatest(REQUEST_EDIT_IDTHEFT_POLICY, workerEditIdTheftPolicy);
}

function* updateAddressWorker({ payload }) {
  try {
    const {
      responseCode,
      result: { isFutureCoverageCreated },
    } = yield call(Api.updatePolicyAddress, payload);
    if (responseCode == 200) {
      const {
        insurancePolicyId,
        streetAddress,
        isFutureCoverage,
        city,
        stateCode,
        postalCode,
      } = payload;
      yield all([
        put({
          type: POLICY_ADDRESS_UPDATED,
          payload: {
            streetAddress,
            city,
            stateCode,
            postalCode,
            insurancePolicyId,
            isFutureCoverage,
            isFutureCoverageCreated,
          },
        }),
        put(
          addError({
            key: 'policyAddressUpdate',
            message: 'Policy address updated successfully.',
          })
        ),
      ]);
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    yield put(
      addError({
        key: 'policyAddressUpdateError',
        message: err.toString(),
      })
    );
  }
}

function* watchAddressUpdate() {
  yield takeLatest(UPDATE_POLICY_ADDRESS, updateAddressWorker);
}

function* updateBillingFreqWorker({ payload }) {
  try {
    const response = yield call(Api.updatePolicyFrequency, payload);
    if (response.responseCode == 200) {
      const { premiumAmount, frequencyId, insurancePolicyId } = payload;
      yield all([
        put({
          type: POLICY_FREQUENCY_UPDATED,
          payload: {
            premiumAmount,
            frequencyId,
            insurancePolicyId,
          },
        }),
        put(
          addError({
            key: 'policyFrequencyUpdate',
            message: response.message,
          })
        ),
      ]);
    } else if (response.responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.log('failed', err.toString());
    yield put(
      addError({
        key: 'policyFrequencyUpdateError',
        message: err.toString(),
      })
    );
  }
}

function* handleAddinterestedparty({ payload }) {
  try {
    const response = yield call(Api.addInterestedParty, payload);
    if (response.responseCode === 200) {
      yield put({
        type: INTERESTED_PARTY_ADDED,
        payload: {
          ...response.result,
        },
      });
      yield typeof payload.onComplete === 'function' && payload.onComplete();
    } else if (response.responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.log('failed', err.toString());
  }
}

function* handleEditInterestedParty({ payload }) {
  try {
    const response = yield call(Api.editInterestedParty, payload);
    if (response.responseCode === 200) {
      yield put({
        type: INTERESTED_PARTY_UPDATED,
        payload: {
          ...response.result,
        },
      });
      yield typeof payload.onComplete === 'function' && payload.onComplete();
    } else if (response.responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.log('failed', err.toString());
  }
}

function* handleRemoveInterestedParty({ payload }) {
  try {
    const response = yield call(Api.removeInterestedParty, payload);
    if (response.responseCode === 200) {
      yield put({
        type: INTERESTED_PARTY_DELETED,
        payload: {
          id: payload.interestedPartyId,
        },
      });
      yield typeof payload.onComplete === 'function' && payload.onComplete();
    } else if (response.responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.log('failed', err.toString());
  }
}

function* workerDownloadPolicy({ payload }) {
  try {
    const response = yield call(Api.downloadPolicy, payload);
    if (response.responseCode === 200) {
      const { file, name } = response.result;
      const link = document.createElement('a');
      link.href = `data:application/pdf;base64,${file}`;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else if (response.responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
  } catch (error) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(error);
    console.log('failed', error.toString());
  }
}

function* revertPolicyCancellation({ payload }) {
  yield put(setLoading());
  try {
    const { message, responseCode } = yield call(
      Api.revertPolicyCancellation,
      payload
    );
    if (responseCode === 200) {
      yield put(
        addError({
          key: 'policyRevertCancellationSuccess',
          message: 'Policy Reverted Successfully.',
        })
      );
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    } else {
      yield put(
        addError({
          key: 'policyRevertCancellation',
          message,
        })
      );
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    yield put(
      addError({
        key: 'policyRevertCancellation',
        message: err.toString(),
      })
    );
  }
  yield put(unsetLoading());
}

function* workerEditPolicyCoverage({ payload }) {
  try {
    let quoteData = yield select(insertQuoteSelector);
    let response = yield call(Api.editCoverage, {
      ...quoteData,
      ...payload,
    });

    if (response.responseCode === 200) {
      yield put(
        addError({
          key: 'policyEditCoverageSuccess',
          message: response.message,
        })
      );
      yield put(
        addAdditionalInsureds({
          additionalInsureds: payload.additionalInsureds,
          insureds: payload.additionalInsureds,
          isInsuredUpdated: false,
        })
      );
    } else if (response.responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    yield put(
      addError({
        key: 'policyEditCoverageError',
        message: err.toString(),
      })
    );
  }
}

function* workerGetPolicyCoverage({ payload }) {
  yield put(setGlobalLoading(`Loading policy coverage`));
  try {
    const {
      entityId,
      userId,
      employeeId,
      insurancePolicyId,
      policy: { cid, propertyId },
    } = payload;

    let { responseCode, result } = yield call(Api.getPolicyCoverage, {
      entityId,
      userId,
      employeeId,
      insurancePolicyId,
      cid: cid,
      propertyId: propertyId,
    });

    if (responseCode === 200) {
      let pricingData = parsePricingData(result);
      let quoteData = parseQuoteData(result);

      let {
        propertyDetails,
        availableProducts,
        additionalInsureds = [],
        coverages: { products },
        defaultValues: {
          endorsements,
          deductableLimit,
          frequency,
          liabilityLimit,
          personalLimit,
          totalPremium,
          leadSourceTypeId,
        },
        propertyLimitsDefaultValues,
        nextCoveragePeriodStartDate,
        changesEffectiveOnDate,
      } = result;
      const isAllowLiabilityOnly = personalLimit ? false : true;
      quoteData.values = {
        ...quoteData.values,
        billingFrequency: frequency,
        carrierLimits_deductibleLimits_1: deductableLimit,
        carrierLimits_liabilityLimits_1: liabilityLimit,
        carrierLimits_personalContentLimits_1: isAllowLiabilityOnly
          ? 'Liability Only'
          : personalLimit,
        endorsements_1: isAllowLiabilityOnly ? [] : endorsements,
        propertyLimitsDefaultValues,
      };

      let enhancementEndorsments = Object.values(
        products[1].endorsements
      ).filter(endorsment => endorsment.isEnhancementEndorsement);

      yield all([
        put(setProperty({ value: propertyId })),
        put(setLeadSourceType({ value: leadSourceTypeId })),
        put(setInsurancePolicy({ insurancePolicyId })),
        put(
          addAdditionalInsureds({
            additionalInsureds,
            insureds: additionalInsureds,
          })
        ),
        put({
          type: RECEIVE_PRICING,
          payload: pricingData,
        }),
        put({
          type: RECEIVE_AVAILABLE_PRODUCTS,
          payload: { availableProducts },
        }),
        put({
          type: INIT_QUOTE,
          payload: {
            products: quoteData.products,
            values: quoteData.values,
            billingFrequencies,
            location: propertyDetails,
            totalPremium: totalPremium,
            currentPremium: totalPremium,
            nextCoveragePeriodStartDate: nextCoveragePeriodStartDate,
            showSubmitLater: false,
            enhancementEndorsments,
            changesEffectiveOnDate,
          },
        }),
        put({
          type: RECEIVE_LOCATION_DATA,
          payload: {
            properties: {
              [propertyId]: {
                ...propertyDetails,
                minimumBillingFrequency: frequency,
                insuranceCarrierId: propertyDetails.carrierId,
              },
            },
          },
        }),
        put(toggleLiabilityOnly({ isLiabilityOnly: isAllowLiabilityOnly })),
        put(
          toggleEnhancementEndorsement({
            id: quoteData.values.defaultEnhancementEndorsement,
          })
        ),
      ]);

      yield delay(1500);
      let premiumData = yield select(getPricingSelector);
      yield put(updatePremium(calculatePremium(premiumData,true)));
      typeof payload.onComplete === 'function' && payload.onComplete();
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.log('failed => ', err);
  }
  yield put(unsetGlobalLoading());
}

function* workerUpdatePolicyMailingAddress({ payload }) {
  try {
    const { responseCode } = yield call(
      Api.updatePolicyMailingAddress,
      payload
    );
    if (responseCode === 200) {
      yield put(
        addError({
          key: 'policyMailingAddressSuccess',
          message: 'Policy mailing address updated successfully.',
        })
      );

      yield put({
        type: RECEIVED_POLICY_MAILING_ADDRESS,
        payload: {
          ...payload,
          mailingStreetLine1: payload.mailingStreetLine,
          mailingStateCode: payload.mailingState,
        },
      });
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.log(err.toString());
  }
}

function* workerGetPetHealthInsuranceLink({ payload }) {
  try {
    const { responseCode } = yield call(Api.getPetHealthInsuranceLink, payload);
    if (responseCode === 200) {
      typeof payload.onComplete === 'function' && payload.onComplete();
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.log(err.toString());
  }
}

function* watchGetPetHealthInsuranceLink() {
  yield takeLatest(
    GET_PET_HEALTH_INSURANCE_LINK,
    workerGetPetHealthInsuranceLink
  );
}

function* watchEditPolicyCoverage() {
  yield takeLatest(REQUEST_EDIT_COVERAGE, workerEditPolicyCoverage);
}

function* watchGetPolicyCoverage() {
  yield takeLatest(REQUEST_POLICY_COVERAGE, workerGetPolicyCoverage);
}

function* watchFrequencyUpdate() {
  yield takeLatest(UPDATE_POLICY_FREQUENCY, updateBillingFreqWorker);
}

function* watchAddInterestedParty() {
  yield takeLatest(ADD_INTERESTED_PARTY, handleAddinterestedparty);
}

function* watchEditInterestedParty() {
  yield takeLatest(EDIT_INTERESTED_PARTY, handleEditInterestedParty);
}

function* watchRemoveInterestedParty() {
  yield takeLatest(REMOVE_INTERESTED_PARTY, handleRemoveInterestedParty);
}

function* watchDownloadPolicy() {
  yield takeLatest(POLICY_DOWNLOAD_REQUEST, workerDownloadPolicy);
}

function* watchRevertPolicyCancellation() {
  yield takeLatest(
    POLICY_REVERT_CANCELLATION_REQUEST,
    revertPolicyCancellation
  );
}

function* watchUpdatePolicyMailingAddress() {
  yield takeLatest(
    REQUEST_POLICY_MAILING_ADDRESS,
    workerUpdatePolicyMailingAddress
  );
}

export const sagas = [
  watchGetActivePolicies,
  watchGetExpiredPolicies,
  watchGetIdTheftProductDetails,
  watchGetPolicyDetails,
  watchAddPolicy,
  watchEditIdTheftPolicy,
  watchAddressUpdate,
  watchFrequencyUpdate,
  watchAddInterestedParty,
  watchEditInterestedParty,
  watchRemoveInterestedParty,
  watchPolicyCancellation,
  watchDownloadPolicy,
  watchRevertPolicyCancellation,
  watchEditPolicyCoverage,
  watchGetPolicyCoverage,
  watchUpdatePolicyMailingAddress,
  watchGetPetHealthInsuranceLink,
];
