/* eslint-disable */
import { createSelector } from 'reselect';
import { filterOrAdd } from '@entrata/utils';
import { INIT_QUOTE, UPDATE_PREMIUM } from './quote';
import isAfter from 'date-fns/is_after';

// action types
export const UPDATE_BILLING_FREQUENCY =
  'ri/quoteValues/UPDATE_BILLING_FREQUENCY';
export const UPDATE_LIMIT_VALUE = 'ri/quoteValues/UPDATE_LIMIT_VALUE';
export const UPDATE_ENDORSEMENT_VALUE =
  'ri/quoteValues/UPDATE_ENDORSEMENT_VALUE';
export const TOGGLE_ENDORSEMENT = 'ri/quoteValues/TOGGLE_ENDORSEMENT';
export const TOGGLE_THEFT_ENDORSEMENT =
  'ri/quoteValues/TOGGLE_THEFT_ENDORSEMENT';
export const CLEAR_QUOTE_VALUES = 'ri/quoteValues/CLEAR_QUOTE_VALUES';
const TOGGLE_LIABILITYONLY = 'ri/quoteValues/TOGGLE_LIABILITYONLY';
export const TOGGLE_ENHANCEMENT_ENDORSEMENT =
  'ri/quoteValues/TOGGLE_ENHANCEMENT_ENDORSEMENT';
const SET_DEDUCTIBLE_LIMIT_ID = 'ri/quoteValues/SET_DEDUCTIBLE_LIMIT_ID';

// reducer
const initialState = {
  billingFrequency: 4,
  defaults: {},
  isLiabilityOnly: false,
};

export default (state = initialState, { payload, type }) => {
  const handleToggleEnhancementEndorsement = () => {
    const {
      endorsements_1,
      defaults: { defaultEnhancementEndorsement },
    } = state;
    const endorsementsData = [
      ...(endorsements_1 &&
        endorsements_1.filter(id => id != defaultEnhancementEndorsement)),
      payload.id,
    ];

    return {
      ...state,
      defaultEnhancementEndorsement: payload.id,
      endorsements_1: endorsementsData,
      defaults: {
        ...state.defaults,
        defaultEnhancementEndorsement: payload.id,
      },
    };
  };

  switch (type) {
    case INIT_QUOTE:
      return {
        ...state,
        ...payload.values,
        defaults: payload.values,
      };
    case UPDATE_LIMIT_VALUE:
      return {
        ...state,
        [`carrierLimits_${payload.name}_${payload.productId}`]: payload.value,
      };
    case UPDATE_ENDORSEMENT_VALUE:
      return {
        ...state,
        [`endorsements_${payload.productId}`]: payload.value,
      };
    case TOGGLE_ENDORSEMENT: {
      let valueKey = `endorsements_${payload.productId}`;
      return {
        ...state,
        [valueKey]: filterOrAdd(
          state[`endorsements_${payload.productId}`],
          payload.id
        ),
      };
    }
    case TOGGLE_THEFT_ENDORSEMENT: {
      return {
        ...state,
        endorsements_5: payload.id,
      };
    }
    case TOGGLE_ENHANCEMENT_ENDORSEMENT:
      return handleToggleEnhancementEndorsement();
    case UPDATE_BILLING_FREQUENCY:
      return {
        ...state,
        billingFrequency: payload.value,
      };
    case 'RESET_TO_DEFAULTS': {
      return {
        ...state,
        ...state.defualts,
      };
    }
    case SET_DEDUCTIBLE_LIMIT_ID:
      return { ...state, carrierLimits_deductibleLimits_1: payload.id };
    case CLEAR_QUOTE_VALUES:
      return initialState;
    case TOGGLE_LIABILITYONLY:
      return {
        ...state,
        isLiabilityOnly: payload.isLiabilityOnly,
      };
    default:
      return state;
  }
};

// action creators
export const updateBillingFrequency = payload => ({
  type: UPDATE_BILLING_FREQUENCY,
  payload,
});

export const updateLimitValue = payload => ({
  type: UPDATE_LIMIT_VALUE,
  payload,
});

export const updateEndorsementValue = payload => ({
  type: UPDATE_ENDORSEMENT_VALUE,
  payload,
});

export const toggleEndorsement = payload => ({
  type: TOGGLE_ENDORSEMENT,
  payload,
});

export const toggleTheftEndorsement = payload => ({
  type: TOGGLE_THEFT_ENDORSEMENT,
  payload,
});

export const updatePremium = payload => ({
  type: UPDATE_PREMIUM,
  payload,
});

export const setDeductibleLimitId = payload => ({
  type: SET_DEDUCTIBLE_LIMIT_ID,
  payload,
});

export const toggleLiabilityOnly = payload => ({
  type: TOGGLE_LIABILITYONLY,
  payload,
});

export const toggleEnhancementEndorsement = payload => ({
  type: TOGGLE_ENHANCEMENT_ENDORSEMENT,
  payload,
});

// Selectors
export const quoteValuesSelector = state => state.quoteValues;
export const billingFrequenciesSelector = state =>
  state.quote.billingFrequencies;
export const quoteSelector = state => state.quote;
export const propertiesSelector = state => state.properties;
export const getQuoteSelector = state => state.getQuote;
const availableProducts = state => state.availableProducts;

export const activeBillingFrequencySelector = createSelector(
  billingFrequenciesSelector,
  quoteValuesSelector,
  (billingFrequencies, quoteValues) =>
    billingFrequencies[quoteValues.billingFrequency]
);

export const REPLACEMENT_COST_ENDORSEMENT = 1;
export const WATER_DAMAGE_ENDORSEMENT = 3;
export const EARTHQUAKE_ENDORSEMENT = 7;
export const SILVER_ENHANCEMENT_ENDORSEMENT = 17;
export const WIND_HAIL_COVERAGE_EXLUSION_ENDORSEMENT = 21;
export const NON_HURRICANE_FACTOR_WITHOUT_WIND_HAIL_ENDORSEMENT = 22;
export const NON_HURRICANE_FACTOR_WITH_WIND_HAIL_ENDORSEMENT = 23;
export const HURRICANE_FACTOR_ENDORSEMENT = 24;
export const NON_HURRICANE_PREMIUM_SUBJECT_TO_WIND_ENDORSEMENT = 25;
export const GOLD_ENHANCEMENT_ENDORSEMENT = 26;
export const PLATINUM_ENHANCEMENT_ENDORSEMENT = 27;

export const calculatePremium = (pricingData, firstTime) =>
  pricingData.stateCode == 'FL'
    ? calculatePremiumFL(pricingData, firstTime)
    : calculatePremiumNonFL(pricingData, firstTime);

export const calculatePremiumFL = (pricingData, firstTime) => {
  const {
    selectedEndorsements,
    availableEndorsements,
    personalContentBaseRate,
    deductibleBaseMultiplier,
    windstormConstructionCredit,
    automaticSprinklerCredit,
    buildingCodeCreditFactor,
    liabilityBaseRate,
    hurricaneDeductibleBaseMultipler,
    statePolicyFigaFee,
    statePolicyFigaeFee,
    idTheftEndorsementId,
    allIdTheftEndorsements,
    idTheftPropertyFee,
    idTheftAdminFee,
    statePolicyFee,
  } = pricingData;

  const nonhurricaneFactorWindhallBaseMultiplier = getEndorsementBaseMultiplier(
    availableEndorsements,
    NON_HURRICANE_FACTOR_WITH_WIND_HAIL_ENDORSEMENT
  );

  const hurricaneFactorBaseMultiplier = getEndorsementBaseMultiplier(
    availableEndorsements,
    HURRICANE_FACTOR_ENDORSEMENT
  );

  const nonHurricanePremiumSubjectToWindhallBaseMultiplier =
    getEndorsementBaseMultiplier(
      availableEndorsements,
      NON_HURRICANE_PREMIUM_SUBJECT_TO_WIND_ENDORSEMENT
    );

  const totalWaterBackupSewersDrains = getWaterDamageBaseRate(
    availableEndorsements,
    selectedEndorsements
  );

  const subTotal1 = parseFloat(
    parseFloat(personalContentBaseRate * hurricaneFactorBaseMultiplier).toFixed(2)
  );

  const subTotal2 = parseFloat(
    parseFloat(
      personalContentBaseRate * nonhurricaneFactorWindhallBaseMultiplier
    ).toFixed(2)
  );

  const subTotal3 = parseFloat(
    parseFloat(
      personalContentBaseRate *
        nonhurricaneFactorWindhallBaseMultiplier *
        deductibleBaseMultiplier
    ).toFixed(2)
  );

  const subTotal4 = parseFloat(
    parseFloat(
      personalContentBaseRate *
        hurricaneFactorBaseMultiplier *
        hurricaneDeductibleBaseMultipler
    ).toFixed(2)
  );

  const subTotal5 = parseFloat(
    parseFloat(
      parseFloat(subTotal1 + subTotal4) * windstormConstructionCredit
    ).toFixed(2)
  );

  const subTotal6 = parseFloat(
    parseFloat(subTotal2 - subTotal3) *
      windstormConstructionCredit *
      nonHurricanePremiumSubjectToWindhallBaseMultiplier
  );

  const subTotal7 = parseFloat(
    parseFloat(subTotal2 - subTotal3 - subTotal6).toFixed(2)
  );

  const subTotal8 = parseFloat(
    parseFloat(parseFloat(subTotal1 + subTotal4) - subTotal5).toFixed(2)
  );

  const buildingCodeEffectivenessGradingCreditOrSurcharge = parseFloat(
    parseFloat(subTotal8 * buildingCodeCreditFactor).toFixed(2)
  );

  const automaticSprinklerCreditNonHurricane = parseFloat(
    parseFloat(subTotal7 * automaticSprinklerCredit).toFixed(2)
  );

  const automaticSprinklerCreditHurricane = parseFloat(
    parseFloat(subTotal8 * automaticSprinklerCredit).toFixed(2)
  );

  const totalNonHurricanePremium = parseFloat(
    parseFloat(
      parseFloat(subTotal7 + liabilityBaseRate) -
        automaticSprinklerCreditNonHurricane
    ).toFixed(0)
  );

  const hurricanePremium = parseFloat(
    parseFloat(
      subTotal8 +
        (buildingCodeEffectivenessGradingCreditOrSurcharge -
          automaticSprinklerCreditHurricane)
    ).toFixed(0)
  );

  const totalPersonalPremiumAmount = parseFloat(
    parseFloat(totalNonHurricanePremium) + parseFloat(hurricanePremium)
  );

  const personalPremiumAmount = totalPersonalPremiumAmount / 12;
  const WaterDemagePremium = totalWaterBackupSewersDrains / 12;

  let totalPremium = Math.round(
    totalPersonalPremiumAmount +
      totalWaterBackupSewersDrains
  );

  totalPremium = parseFloat(parseFloat(totalPremium / 12).toFixed(2));

  // Calculate FIGA and FIGAE premium
  const figaPremium = calculateFigaPremium(
    totalPremium,
    statePolicyFigaFee
  );
  const figaePremium = calculateFigaePremium(
    totalPremium,
    statePolicyFigaeFee
  );

  totalPremium = totalPremium + figaPremium + figaePremium;

  let nonHurricanePremium = totalNonHurricanePremium / 12;

  // for id theft premium
  let idTheftPremiumAmount = 0;
  if (
    idTheftEndorsementId &&
    Object.entries(allIdTheftEndorsements).length
  ) {
    idTheftPremiumAmount =
      parseFloat(
        allIdTheftEndorsements[idTheftEndorsementId]
          .premiumAmount
      ) + idTheftPropertyFee;
  }

  const monthlyIdTheftPremiumAmount = idTheftPremiumAmount;
  const monthlyPremiumAmount = totalPremium;

  pricingData.adminFee = parseFloat(pricingData.adminFee);
  if(pricingData?.isPercentile) {
    pricingData.adminFee  = parseFloat(calculateAdminFee(pricingData.percentileAdminFee, totalPremium));
  } 

  const adminFee =  parseInt(pricingData.billingFrequency) === 6 ? 0 : pricingData.adminFee;    
  
  switch (parseInt(pricingData.billingFrequency)) {
    case 4:
      totalPremium *= 1;
      idTheftPremiumAmount *= 1;
      break;
    case 5:
      totalPremium *= 3;
      idTheftPremiumAmount *= 3;
      break;
    case 6:
      totalPremium *= 12;
      idTheftPremiumAmount *= 12;
      break;
    case 10:
      totalPremium *= 6;
      idTheftPremiumAmount *= 6;
      break;
    default:
      break;
  }

  totalPremium += idTheftPremiumAmount + parseFloat(adminFee);

  const premiumData = {
    personalPremiumAmount: personalPremiumAmount,
    liabilityPremium: 0,
    totalPremium: parseFloat(totalPremium).toFixed(2),
    additionalTaxAmount: 0,
    earthQuakePremiumAmount: 0,
    premiumTaxAmount: 0,
    idTheftPremiumAmount,
    adminFee,
    idTheftAdminFee: idTheftAdminFee,
    monthlyIdTheftPremiumAmount,
    monthlyPremiumAmount,
    waterDemagePremium: WaterDemagePremium,
    nonHurricanePremium,
    statePolicyFee: statePolicyFee,
    figaPremium,
    figaePremium,
  };

  return firstTime
    ? {
        ...premiumData,
        firstTimeMonthlyPremiumAmount:
          parseFloat(monthlyPremiumAmount).toFixed(2),
      }
    : premiumData;
};

export const getEndorsementBaseMultiplier = (
  availableEndorsements,
  endorsementTypeId
) => {
  const endorsement = availableEndorsements.find(
    e => Object.values(e)[0].endorsementTypeId == endorsementTypeId
  );
  return endorsement
    ? parseFloat(Object.values(endorsement)[0].baseMultiplier)
    : 1;
};

export const getWaterDamageBaseRate = (
  availableEndorsements,
  selectedEndorsements
) => {
  const waterEndorsement = availableEndorsements.find(
    e => Object.values(e)[0].endorsementTypeId == WATER_DAMAGE_ENDORSEMENT
  );
  return waterEndorsement &&
    selectedEndorsements.includes(Object.values(waterEndorsement)[0].id)
    ? parseFloat(Object.values(waterEndorsement)[0].baseRate)
    : 0;
};

export const calculatePremiumNonFL = (pricingData, firstTime) => {
  let { availableEndorsements, selectedEndorsements } = pricingData;

  const replacementCostEndorsement = Object.values(
    availableEndorsements.find(item => {
      let endorsement = Object.values(item)[0];
      if (endorsement.endorsementTypeId == REPLACEMENT_COST_ENDORSEMENT) {
        return true;
      }
      return false;
    }) || {}
  )[0];

  let replacementCost = selectedEndorsements.includes(
    replacementCostEndorsement?.id
  )
    ? parseFloat(replacementCostEndorsement.baseMultiplier)
    : 1;

  pricingData.replacementCost = replacementCost;

  let personalPremiumAmount = calculatePersonalPremium(pricingData);
  let liabilityPremium = calculateLiabilityPremium(pricingData);
  let earthQuakePremiumAmount = calculateEarthquakePremium(pricingData);

  let totalPremium = parseFloat(
    personalPremiumAmount + liabilityPremium + earthQuakePremiumAmount
  );

  // For CT state only because it provides annual policy only.
  if (pricingData.stateCode == 'CT') {
    personalPremiumAmount = calculateAnnualPremium(personalPremiumAmount);
    liabilityPremium = calculateAnnualPremium(liabilityPremium);
    earthQuakePremiumAmount = calculateAnnualPremium(earthQuakePremiumAmount);

    totalPremium = parseFloat(
      personalPremiumAmount + liabilityPremium + earthQuakePremiumAmount
    ).toFixed(2);

    totalPremium = parseFloat(totalPremium);
  }

  const premiumTaxAmount = calculateTaxesFromGross(
    totalPremium,
    pricingData.stateCode
  );

  totalPremium += premiumTaxAmount;

  let { additionalTaxAmount, updatedTotalPremium } = getAdditionalTaxAmount(
    pricingData,
    liabilityPremium,
    totalPremium
  );

  totalPremium = parseFloat(updatedTotalPremium);

  pricingData.adminFee = parseFloat(pricingData.adminFee);
  if(pricingData?.isPercentile) {
    pricingData.adminFee  = parseFloat(calculateAdminFee(pricingData.percentileAdminFee, totalPremium));
  } 

  let idTheftPremiumAmount = 0;
  if (
    pricingData.idTheftEndorsementId &&
    Object.entries(pricingData.allIdTheftEndorsements).length
  ) {
    idTheftPremiumAmount =
      parseFloat(
        pricingData.allIdTheftEndorsements[pricingData.idTheftEndorsementId]
          .premiumAmount
      ) + pricingData.idTheftPropertyFee;
  }

  const monthlyIdTheftPremiumAmount = idTheftPremiumAmount;
  const monthlyPremiumAmount = totalPremium;

  let billingFrequency = parseInt(pricingData.billingFrequency);
  switch (billingFrequency) {
    case 4:
      totalPremium *= 1;
      idTheftPremiumAmount *= 1;
      break;
    case 5:
      totalPremium *= 3;
      idTheftPremiumAmount *= 3;
      break;
    case 6:
      pricingData.adminFee = 0;

      if (pricingData.stateCode == 'CT') {
        totalPremium = parseFloat(parseFloat((totalPremium *= 12)).toFixed(0));
      } else {
        totalPremium *= 12;
      }

      idTheftPremiumAmount *= 12;
      break;
    case 10:
      totalPremium *= 6;
      idTheftPremiumAmount *= 6;
      break;
    default:
      break;
  }

  totalPremium = totalPremium + pricingData.adminFee;
  totalPremium += idTheftPremiumAmount;

  let premiumData = {
    personalPremiumAmount,
    liabilityPremium,
    totalPremium: parseFloat(totalPremium).toFixed(2),
    additionalTaxAmount,
    earthQuakePremiumAmount,
    premiumTaxAmount,
    idTheftPremiumAmount,
    adminFee: pricingData.adminFee,
    idTheftAdminFee: pricingData.idTheftAdminFee,
    monthlyIdTheftPremiumAmount,
    monthlyPremiumAmount,
    nonHurricanePremium: 0,
    statePolicyFee: pricingData.statePolicyFee,
    WaterDemagePremium: 0,
    figaPremium: 0,
    figaePremium: 0,
  };

  return firstTime
    ? {
        ...premiumData,
        firstTimeMonthlyPremiumAmount:
          parseFloat(monthlyPremiumAmount).toFixed(2),
      }
    : premiumData;
};

const calculateFigaPremium = (totalPremium, statePolicyFigaFee) =>
  (parseFloat(totalPremium) * parseFloat(statePolicyFigaFee)) / 100;

const calculateFigaePremium = (totalPremium, statePolicyFigaeFee) =>
  (parseFloat(totalPremium) * parseFloat(statePolicyFigaeFee)) / 100;

const calculatePersonalPremium = ({
  availableEndorsements,
  selectedEndorsements,
  personalContentBaseRate,
  enhancementEndorsement,
  golfcartPremium,
  deductibleBaseMultiplier,
  preferredProperty,
  identityFraudPremium,
  acquisition,
}) => {
  let personalPremiumAmount = 0;
  let endorsementMultipliedPremium = 1;

  const enhancementArr = [
    SILVER_ENHANCEMENT_ENDORSEMENT,
    GOLD_ENHANCEMENT_ENDORSEMENT,
    PLATINUM_ENHANCEMENT_ENDORSEMENT,
  ];
  endorsementMultipliedPremium = availableEndorsements.reduce((acc, item) => {
    let endorsement = Object.values(item)[0];

    if (endorsement.endorsementTypeId === EARTHQUAKE_ENDORSEMENT) {
      return acc;
    }

    if (
      selectedEndorsements.includes(endorsement.id) &&
      !enhancementArr.includes(endorsement.endorsementTypeId)
    ) {
      return acc * parseFloat(endorsement.baseMultiplier);
    }

    return acc;
  }, endorsementMultipliedPremium);

  const subTotal1 =
    personalContentBaseRate + enhancementEndorsement + golfcartPremium;
  const subTotal2 =
    subTotal1 *
    endorsementMultipliedPremium *
    deductibleBaseMultiplier *
    preferredProperty;
  const subTotal3 = subTotal2 + identityFraudPremium;
  personalPremiumAmount = parseFloat(subTotal3 * acquisition).toFixed(2);

  return parseFloat(personalPremiumAmount);
};

const calculateLiabilityPremium = ({
  liabilityCoverage,
  liabilityBaseMultiplier,
  preferredProperty,
  acquisition,
}) => {
  let liability_premium = parseFloat(
    liabilityCoverage *
      liabilityBaseMultiplier *
      preferredProperty *
      acquisition
  ).toFixed(2);
  return parseFloat(liability_premium);
};

const calculateEarthquakePremium = ({
  availableEndorsements,
  selectedEndorsements,
  personalLimitAmount,
  earthquakeBaseMultiplier,
  replacementCost,
  preferredProperty,
  acquisition,
  stateCode,
}) => {
  let erthQuakePremiumAmount = 0;

  let selectedEndorsementsData = Object.values(availableEndorsements).reduce(
    (acc, endorsement) => {
      endorsement = Object.values(endorsement)[0];
      if (selectedEndorsements.includes(endorsement.id)) {
        return [...acc, endorsement.endorsementTypeId];
      }
      return acc;
    },
    []
  );

  if (
    selectedEndorsements.length == 0 ||
    !selectedEndorsementsData.includes(EARTHQUAKE_ENDORSEMENT)
  ) {
    return parseFloat(erthQuakePremiumAmount);
  }

  const subTotal1 = personalLimitAmount / 100;
  const subTotal2 = subTotal1 * earthquakeBaseMultiplier;

  if (stateCode == 'CT') {
    erthQuakePremiumAmount =
      subTotal2 * replacementCost * preferredProperty * acquisition;
    return parseFloat(erthQuakePremiumAmount);
  }

  erthQuakePremiumAmount = parseFloat(
    subTotal2 * replacementCost * preferredProperty * acquisition
  ).toFixed(2);

  return parseFloat(erthQuakePremiumAmount);
};

const getAdditionalTaxAmount = (
  pricingData,
  liabilityPremium,
  totalPremium
) => {

  let cityWiseTaxRateForKyState = pricingData.kyCityTaxRates

  let endorsementTax = 0;
  let stateCode = pricingData.stateCode.toLowerCase();

  totalPremium = parseFloat(totalPremium);
  liabilityPremium = parseFloat(liabilityPremium);

  if ('mn' == stateCode) {
    endorsementTax = calculateFireManReliefSurcharge(
      totalPremium,
      liabilityPremium
    );
    totalPremium += parseFloat(endorsementTax);
  }

  if ('ky' == stateCode) {
    const city = pricingData.city.trim().toUpperCase().replace(/[.\-!~#*()\s]/g, '_');

    let cityTaxRate = 0;
    if (Object.hasOwn(cityWiseTaxRateForKyState, city)) {
      cityTaxRate = cityWiseTaxRateForKyState[city];
    }

    endorsementTax = calculateKyMunicipalTax(totalPremium, cityTaxRate);
    totalPremium += parseFloat(endorsementTax);
  }

  return {
    additionalTaxAmount: endorsementTax,
    updatedTotalPremium: totalPremium,
  };
};

const calculateFireManReliefSurcharge = (grossPremium, liabilityPremium) => {
  let firemenReliefSurcharge = grossPremium - liabilityPremium;
  return parseFloat((firemenReliefSurcharge * 2) / 100).toFixed(2);
};

const calculateKyMunicipalTax = (grossPremium, cityTaxRate) => {
  let kyMunicipalTax = parseFloat((grossPremium * cityTaxRate) / 100).toFixed(
    2
  );
  return parseFloat(kyMunicipalTax);
};

const calculateTaxesFromGross = (totalPremium, stateCode) => {
  let taxMultipliers = {
    WV: 0.55,
    LA: isAfter(new Date(), new Date('12/31/2022')) ? 2.1 : 2.4,
    MN: 0.65,
    KY: 1.8,
  };

  let taxes = 0;

  if (taxMultipliers[stateCode]) {
    taxes = totalPremium * (taxMultipliers[stateCode] / 100);
  }

  taxes = parseFloat(taxes).toFixed(2);
  return parseFloat(taxes);
};

export const getPricingSelector = createSelector(
  quoteValuesSelector,
  quoteSelector,
  propertiesSelector,
  getQuoteSelector,
  availableProducts,
  (
    {
      billingFrequency,
      endorsements_1,
      endorsements_5,
      carrierLimits_liabilityLimits_1,
      carrierLimits_personalContentLimits_1,
      carrierLimits_deductibleLimits_1,
      carrierLimits_hurricaneDeductibleLimits_1,
      defaultEnhancementEndorsement,
    },
    {
      products,
      location,
      statePolicyFigaFee,
      statePolicyFigaeFee,
      isStatePolicyFeeApplicable,
      statePolicyFee,
    },
    properties,
    { property, leadSourceType },
    availableProducts
  ) => {
    if(Object.values(products).length === 0) {
      return {}
    }
    let { carrierLimits, endorsements } = products[1];
    let enhancementEndorsementId = defaultEnhancementEndorsement
      ? defaultEnhancementEndorsement
      : products[1].enhancementEndorsementId;

    endorsements = Object.values(endorsements);
    let allIdTheftEndorsements = Object.keys(products).includes('5')
      ? products[5].endorsements
      : {};
    let personalContentLimit = carrierLimits.personalContentLimits.options.find(
      option => option.id == carrierLimits_personalContentLimits_1
    );
    let liabilityLimit = carrierLimits.liabilityLimits.options.find(
      option => option.id == carrierLimits_liabilityLimits_1
    );
    let deductibleLimit = carrierLimits.deductibleLimits.options.find(
      option => option.id == carrierLimits_deductibleLimits_1
    );

    let hurricaneDeductibleLimit = {};
    if (location.stateCode == 'FL') {
      hurricaneDeductibleLimit =
        carrierLimits.hurricaneDeductibleLimits.options.find(
          option => option.id == carrierLimits_hurricaneDeductibleLimits_1
        );
    }

    return {
      liabilityLimit: liabilityLimit,
      deductibleLimit: deductibleLimit,
      personalContentLimit: personalContentLimit,
      leadSourceTypeId: leadSourceType,
      acquisition: parseFloat(
        endorsements.find(
          endorsement => endorsement.name === 'Acquisition Endorsement'
        )?.baseMultiplier || 0
      ),
      preferredProperty:
        location.carrierClassificationTypeId == 2
          ? 1
          : parseFloat(
              endorsements.find(
                endorsement => endorsement.name === 'Preferred Property'
              )?.baseMultiplier || 0
            ),
      liabilityCoverage: parseFloat(
        endorsements.find(
          endorsement => endorsement.name === 'Liability Coverage'
        )?.baseRate || 0
      ),
      replacementCost: parseFloat(
        endorsements.find(
          endorsement => endorsement.name === 'Replacement Cost'
        )?.baseMultiplier || 0
      ),
      earthquakeBaseMultiplier: parseFloat(
        endorsements.find(endorsement => endorsement.name === 'Earthquake')
          ?.baseMultiplier || 0
      ),
      nonhurricaneFactorWithoutWindhall: 1,
      nonhurricaneFactorWindhall: 1,
      hurricane: 1,
      nonHurricanePremium: 1,
      windHailCoverageExclusion: false,
      waterBackUp: 0,
      personalContentBaseRate: personalContentLimit
        ? parseFloat(personalContentLimit.baseRate)
        : 0,
      personalLimitAmount: personalContentLimit
        ? parseFloat(personalContentLimit.value)
        : 0,
      liabilityBaseMultiplier: liabilityLimit
        ? parseFloat(liabilityLimit.baseMultiplier)
        : 0,
      deductibleBaseMultiplier: deductibleLimit
        ? parseFloat(deductibleLimit.baseMultiplier)
        : 0,
      hurricaneDeductibleBaseMultipler: hurricaneDeductibleLimit
        ? parseFloat(hurricaneDeductibleLimit.baseMultiplier)
        : 0,
      selectedEndorsements: endorsements_1,
      availableEndorsements: endorsements.map(endorsement => ({
        [endorsement.id]: {
          id: endorsement.id,
          baseMultiplier: endorsement.baseMultiplier,
          endorsementTypeId: endorsement.endorsementTypeId,
          baseRate: endorsement.baseRate,
        },
      })),
      identityFraudPremium: 0,
      golfcartPremium: 0,
      enhancementEndorsement: parseFloat(
        endorsements.find(
          endorsement => endorsement.id == enhancementEndorsementId
        )?.baseMultiplier || 0
      ),
      stateCode: properties[property].stateCode,
      city: properties[property].city,
      liabilityBaseRate:
        location.stateCode.toLowerCase() == 'fl'
          ? parseFloat(liabilityLimit?.baseRate) || 0
          : 0,
      billingFrequency: billingFrequency,
      idTheftEndorsementId: endorsements_5,
      windstormConstructionCredit: location.windstorm_construction_credit,
      automaticSprinklerCredit: location.automatic_sprinkler_credit,
      buildingCodeCreditFactor: location.building_code_credit_factor,
      allIdTheftEndorsements,
      adminFee: parseFloat(availableProducts[1].adminFee),
      isPercentile:availableProducts[1].isPercentile,
      percentileAdminFee:availableProducts[1].percentileAdminFee,
      statePolicyFigaFee,
      statePolicyFigaeFee,
      isStatePolicyFeeApplicable,
      statePolicyFee,
      idTheftPropertyFee:
        (availableProducts[5] &&
          parseFloat(availableProducts[5].idTheftPropertyFee)) ||
        0,
      idTheftAdminFee:
        endorsements_5 && allIdTheftEndorsements[endorsements_5]
          ? parseFloat(allIdTheftEndorsements[endorsements_5].adminFee)
          : 0,
      kyCityTaxRates: availableProducts[1].kyCityTaxRates
    };
  }
);

const calculateAnnualPremium = fltPremium => {
  let fltPremiumAmountYearly = parseFloat(
    parseFloat(fltPremium * 12).toFixed(0)
  );
  return parseFloat(parseFloat(fltPremiumAmountYearly / 12).toFixed(2));
};

const calculateAdminFee = ( percentile, premiumAmount ) => {
  return parseFloat( parseFloat(premiumAmount) * (parseFloat(percentile) / 100) ).toFixed(2)
}