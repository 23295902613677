import { createSelector } from 'reselect';
import { getQuoteSelector } from './getQuote';

// action types
export const CLEAR_LOCATION = 'ri/quote/CLEAR_LOCATION';
export const CLEAR_QUOTE = 'ri/quote/CLEAR_QUOTE';
export const INIT_QUOTE = 'ri/quote/INIT_QUOTE';
export const UPDATE_PREMIUM = 'ri/quote/UPDATE_PREMIUM';

// reducer
const initialQuoteState = {
  billingFrequencies: {},
  location: {},
  products: {},
  isStatePolicyFeeApplicable: false,
  statePolicyFee: 0,
  statePolicyMessage: null,
};

export default (state = initialQuoteState, action = {}) => {
  const { payload, type } = action;
  switch (type) {
    case INIT_QUOTE:
      return {
        ...state,
        ...payload,
      };
    case UPDATE_PREMIUM:
      return {
        ...state,
        ...payload,
      };
    case CLEAR_QUOTE:
      return initialQuoteState;
    default:
      return state;
  }
};

// selectors
const propertiesSelector = (state) => state.properties;
export const quoteDataSelector = (state) => state.quote;

export const propertySelector = createSelector(
  propertiesSelector,
  getQuoteSelector,
  quoteDataSelector,
  (properties, { property }, { location }) =>
    location.cid
      ? location
      : Object.values(properties).find((p) => parseInt(p.propertyId, 10) === parseInt(property, 10)),
);
