/* eslint-disable */
// import axios from 'axios'
import Api from 'api';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import billingFrequencies from 'constants/billingFrequencies';
import { setGlobalLoading, unsetGlobalLoading } from './globalLoading';
import { createSelector } from 'reselect';
import {
  activeBillingFrequencySelector,
  quoteValuesSelector,
  getPricingSelector,
  updatePremium,
  calculatePremium,
} from './quoteValues';
import { INIT_QUOTE } from './quote';
import { RECEIVE_AVAILABLE_PRODUCTS } from './availableProducts';
import { validateToken } from './user';
import format from 'date-fns/format';
import { addPackageDetails } from './quotePackages';
import { QUOTE_VERSION_V3 } from 'constants/common';

// action types
export const RECEIVE_PRICING = 'ri/pricing/RECEIVE_PRICING';
const REQUEST_PRICING = 'ri/pricing/REQUEST_PRICING';
const REQUEST_PRICING_AFTER_LOGIN = 'ri/pricing/REQUEST_PRICING_AFTER_LOGIN';
export const CLEAR_PRICING = 'ri/pricing/CLEAR_PRICING';

// reducer
const initialState = {
  isStatePolicyFeeApplicable: false,
  statePolicyFee: 0,
  statePolicyMessage: '',
  statePolicyFigaFee: 0,
  statePolicyFigaeFee: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case RECEIVE_PRICING:
      return payload;
    case CLEAR_PRICING:
      return initialState;
    default:
      return state;
  }
};

// action creators
export const requestPricing = (payload) => ({
  type: REQUEST_PRICING,
  payload,
});

export const requestPricingAfterLogin = (payload) => ({
  type: REQUEST_PRICING_AFTER_LOGIN,
  payload,
});

// Helpers
const sortByFormula = (a) => {
  if (a.formulaType && a.formulaType.toLowerCase() === 'factor' && a.factors.endorsements !== undefined) {
    return -1;
  } else {
    return 0;
  }
};

const factorByFrequencyAndRound = (charges, factor) => {
  return charges.map((charge) => ({
    ...charge,
    amount: Math.round(charge.amount * factor * 100) / 100,
  }));
};

const calculateComponentCharge = (product, values, frequency = {}) => {
  let limitCharges = [];
  let endorsementCharges = [];
  let subCharges = [];
  // handle carrierLimits
  if (product.carrierLimits) {
    limitCharges = Object.entries(product.carrierLimits).reduce((acc, [limitKey, limit]) => {
      let value = values[`carrierLimits_${limitKey}_${product.id}`];
      if (value && limit.pricing && limit.pricing.length) {
        let lookup = limit.lookUpVariables.length > 0 && limit.lookUpVariables[0];
        let amount = 0;
        if (lookup) {
          let lookupValue = values[`carrierLimits_${lookup}_${product.id}`];

          amount = parseFloat(limit.pricing[value][lookupValue].cost);
        } else {
          amount = parseFloat(limit.pricing[value].cost);
        }

        return amount > 0
          ? [
              ...acc,
              {
                id: limitKey,
                amount,
              },
            ]
          : acc;
      } else {
        return acc;
      }
    }, []);
  }

  // handle endorsements
  if (product.endorsements) {
    endorsementCharges = Object.values(product.endorsements)
      // Sort to make sure items that factor other endorsements come last
      .sort(sortByFormula)
      .reduce((acc, endorsement) => {
        let value = values[`endorsements_${product.id}`];
        let isSelected = Array.isArray(value) ? value.includes(endorsement.id) : value == endorsement.id;
        let amount = 0;

        if (isSelected) {
          // !- Ignore earthquake endorsement until api is fixed
          if (endorsement.formulaType && endorsement.formulaType.toLowerCase() === 'factor') {
            amount = endorsement.factors.reduce((result, { carrierLimits, endorsements, factor, valueFactor }) => {
              let factorCharge;
              let factorKey;
              let valueFactorCoverageKey;

              if (carrierLimits !== undefined) {
                factorCharge = limitCharges.find((charge) => charge.id === carrierLimits);
                factorKey = carrierLimits;
                valueFactorCoverageKey = 'carrierLimits';
              } else if (endorsements !== undefined) {
                factorCharge = acc.find((charge) => charge.id === endorsements);
                factorKey = endorsements;
                valueFactorCoverageKey = 'endorsements';
              }

              if (factorCharge) {
                if (valueFactor) {
                  let valueFactorValueKey = `${valueFactorCoverageKey}_${factorKey}_${product.id}`;
                  let option = product[valueFactorCoverageKey][factorKey].options.find(
                    (opt) => opt.id == values[valueFactorValueKey],
                  );

                  return result + parseFloat(option.value) * valueFactor;
                } else {
                  return result + factorCharge.amount * factor;
                }
              } else {
                // console.error(
                //   `couldnt find factor carrierLimits ${factorKey} in list of charges`,
                //   { subCharges }
                // )
              }
            }, 0);
          } else if (
            (!endorsement.formulaType || endorsement.formulaType.toLowerCase() === 'fixed') &&
            endorsement.cost
          ) {
            amount = endorsement.cost;
          }
        }

        return amount > 0
          ? [
              ...acc,
              {
                amount,
                id: endorsement.id,
              },
            ]
          : acc;
      }, []);
  }

  subCharges = subCharges.concat(
    factorByFrequencyAndRound(limitCharges, frequency.factor),
    factorByFrequencyAndRound(endorsementCharges, frequency.factor),
  );

  return {
    id: product.id,
    title: product.description,
    amount: subCharges.reduce((total, charge) => total + charge.amount, 0),
  };
};

const pricingSelector = (state) => state.pricing;
const quoteSelector = (state) => state.quote;

export const premiumChargeSelector = createSelector(quoteSelector, (quote) => {
  const { totalPremium, idTheftPremiumAmount, adminFee, isStatePolicyFeeApplicable, statePolicyFee } = quote;
  const rentersInsurance = parseFloat(totalPremium - (idTheftPremiumAmount + adminFee)).toFixed(2);

  let premiumCharges = [
    {
      title: 'Renters Insurance',
      amount: rentersInsurance,
    },
    {
      title: 'Admin Fee',
      amount: adminFee,
    },
  ];

  if (idTheftPremiumAmount) {
    premiumCharges = [
      ...premiumCharges,
      {
        title: 'ID Theft',
        amount: idTheftPremiumAmount,
      },
    ];
  }

  let totalPremiumWithStateFee = 0;
  if (isStatePolicyFeeApplicable) {
    totalPremiumWithStateFee = (parseFloat(totalPremium) + parseFloat(statePolicyFee)).toFixed(2);
  }

  return {
    totalPremium,
    premiumCharges,
    totalPremiumWithStateFee,
  };
});

export const quoteChargesSelector = createSelector(
  pricingSelector,
  quoteValuesSelector,
  activeBillingFrequencySelector,
  quoteSelector,
  (pricing, values, frequency = {}, quote) => {
    let adminFee;

    let combinedCharges = Object.values(pricing).reduce(
      (acc, { productType, ...product }) => {
        let charge;

        if (productType === 'Component') {
          charge = calculateComponentCharge(product, values, frequency);
        } else if (productType === 'Bundeled') {
          charge = calculateComponentCharge(product, values, frequency);
        }
        if (frequency.factor != 12 && product.adminFee !== undefined && parseFloat(product.adminFee)) {
          adminFee = {
            id: 'admin_fee',
            title: 'Admin Fee',
            amount: product.adminFee,
          };
        }

        return {
          total: acc.total + charge?.amount,
          charges: [...acc.charges, charge],
        };
      },
      { total: 0, charges: [] },
    );

    if (adminFee) {
      combinedCharges.charges = combinedCharges.charges.concat(adminFee);
    }

    // combinedCharges.total = Math.round(combinedCharges.total * 100) / 100
    combinedCharges.total = quote.totalPremium;

    return combinedCharges;
  },
);

// const stripNestedObjectKeys = (obj = {}, keys) =>
//   Object.entries(obj).reduce(
//     (acc, [key, coverage]) => ({
//       ...acc,
//       [key]: stripObjectKeys(coverage, keys)
//     }),
//     {}
//   )

// Helper functions
export const parsePricingData = ({ availableProducts, coverages }) => {
  return Object.entries(availableProducts).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: { ...value, ...coverages.products[key] },
    };
  }, {});
};

export const parseQuoteData = ({ availableProducts, coverages }, defaultBillingFrequency = 4) => {
  return Object.keys(availableProducts).reduce(
    ({ products, values }, key) => {
      products = {
        ...products,
        [key]: Object.entries(coverages.products[key]).reduce((accCoverages, [coverageKey, coverage]) => {
          if (coverageKey == 'carrierLimits') {
            return {
              ...accCoverages,
              carrierLimits: Object.entries(coverage).reduce((accLimits, [limitKey, { label, options }]) => {
                let valueKey = `carrierLimits_${limitKey}_${key}`;

                values = {
                  ...values,
                  [valueKey]: options.find((opt) => opt.isSelected).id,
                };

                return {
                  ...accLimits,
                  [limitKey]: {
                    name: limitKey,
                    label,
                    options,
                  },
                };
              }, {}),
            };
          } else if (coverageKey == 'endorsements') {
            let valueKey = `endorsements_${key}`;

            let endorsements = Object.entries(coverage).reduce(
              (
                acc,
                [
                  endorsementKey,
                  {
                    id,
                    name,
                    description,
                    isMandatory,
                    cost = null,
                    isSelected,
                    baseMultiplier = null,
                    baseRate = null,
                    endorsementTypeId = null,
                    adminFee = null,
                    premiumAmount = null,
                  },
                ],
              ) => {
                if (key == 5) {
                  let idTheft = Object.values(coverage).find((idTheft) => idTheft.isSelected) ?? {};
                  values = {
                    ...values,
                    [valueKey]: Object.values(idTheft).length ? idTheft.id : '',
                  };
                } else {
                  // Make sure endorsement value always inits to an array even if none are selected
                  let currentValue = Array.isArray(values[valueKey]) ? values[valueKey] : [];

                  values = {
                    ...values,
                    [valueKey]: isSelected ? [...currentValue, id] : currentValue,
                  };
                }

                return {
                  ...acc,
                  [endorsementKey]: {
                    id,
                    name,
                    description,
                    isMandatory,
                    cost,
                    baseMultiplier,
                    baseRate,
                    endorsementTypeId,
                    adminFee,
                    premiumAmount,
                  },
                };
              },
              {},
            );

            return {
              ...accCoverages,
              endorsements,
            };
          } else {
            return { ...accCoverages, [coverageKey]: coverage };
          }
        }, {}),
      };

      return { products, values };
    },
    {
      products: {},
      values: {
        billingFrequency: defaultBillingFrequency,
        defaultEnhancementEndorsement: coverages.products[1].enhancementEndorsementId || null,
      },
    },
  );
};

// const parseBillingFrequencies = paymentFrequencies => {
//   return Object.entries(paymentFrequencies).reduce(
//     (acc, [key, value]) => ({
//       ...acc,
//       [key]: { ...value, ...billingFrequencies[key] },
//     }),
//     {}
//   )
// }

// API
// const getPricingAPI = async ({ propertyId, employeeId, leadSourceTypeId }) => {
//   try {
//     return await axios.get(
//       `enroll/get_quote?propertyId=${propertyId}&employeeId=${employeeId}&leadSourceTypeId=${leadSourceTypeId}`
//     )
//   } catch (error) {
//     return error
//   }
// }

// sagas
function* getPricing({ payload = {} }) {
  const {
    propertyId,
    employeeId,
    leadSourceTypeId,
    defaultBillingFrequency,
    userId,
    cid,
    customerId,
    leaseId,
    applicantId,
    applicationId,
    selectedProperty,
    startDate,
    isAllowedMicroservice,
  } = yield select(
    ({
      getQuote: {
        property,
        employee,
        leadSourceType,
        user,
        clientId,
        customerId,
        leaseId,
        applicantId,
        applicationId,
        startDate,
      },
      properties,
    }) => ({
      propertyId: property,
      employeeId: employee.id,
      leadSourceTypeId: leadSourceType,
      defaultBillingFrequency: properties[property].minimumBillingFrequency,
      userId: user,
      cid: clientId || properties[property].clientId,
      customerId,
      leaseId,
      applicantId,
      applicationId,
      selectedProperty: properties[property],
      startDate,
      isAllowedMicroservice: properties[property]?.isAllowedMicroservice,
    }),
  );

  yield put(setGlobalLoading(`Almost done! We're preparing your quote now.`));

  const { quoteVersion = 'v2' } = payload;

  try {
    const response = yield call(isAllowedMicroservice ? Api.getPricingFromAWS : Api.getPricing, {
      propertyId,
      employeeId,
      quoteVersion,
      leadSourceTypeId,
      userId,
      cid,
      customerId,
      leaseId,
      applicantId,
      applicationId,
      effectiveDate: format(startDate, 'MM-DD-YYYY'),
    });
    if (response.responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    } else if (!(response.responseCode === 200)) {
      throw new Error(response.message);
    }

    let pricingData = parsePricingData(response);
    let quoteData = parseQuoteData(response, defaultBillingFrequency);
    //let billingFrequencies = parseBillingFrequencies(response)
    let {
      propertyDetails,
      totalPremium,
      showSubmitLater,
      availableProducts,
      isArrayEnrolmentEnabled,
      individualEndorsementTypeId,
      coverages: { products },
    } = response;

    if (selectedProperty.isGeneric) {
      propertyDetails = {
        ...propertyDetails,
        postalCode: selectedProperty.postalCode,
        city: selectedProperty.city,
        stateCode: selectedProperty.stateCode,
      };
    }

    let enhancementEndorsments = Object.values(products[1].endorsements).filter(
      (endorsment) => endorsment.isEnhancementEndorsement,
    );

    const { figaFee, figaeFee, isStatePolicyFeeApplicable, statePolicyFee, statePolicyMessage } = availableProducts[1];

    yield all([
      put({
        type: RECEIVE_PRICING,
        payload: pricingData,
      }),
      put({
        type: RECEIVE_AVAILABLE_PRODUCTS,
        payload: { availableProducts },
      }),
      put({
        type: INIT_QUOTE,
        payload: {
          products: quoteData.products,
          values: {
            ...quoteData.values,
            endorsements_5: quoteData.values?.endorsements_5 || '',
          },
          billingFrequencies,
          location: propertyDetails,
          totalPremium: totalPremium,
          showSubmitLater: showSubmitLater,
          enhancementEndorsments,
          statePolicyFigaFee: figaFee,
          statePolicyFigaeFee: figaeFee,
          isStatePolicyFeeApplicable: isStatePolicyFeeApplicable,
          statePolicyFee: statePolicyFee,
          statePolicyMessage: statePolicyMessage,
          isArrayEnrolmentEnabled: isArrayEnrolmentEnabled,
          individualEndorsementTypeId: individualEndorsementTypeId,
        },
      }),
    ]);

    if (quoteVersion === QUOTE_VERSION_V3) {
      yield put(addPackageDetails(response));
    }

    typeof payload.onComplete === 'function' && payload.onComplete();
    yield delay(1500);
    let premiumData = yield select(getPricingSelector);
    yield put(updatePremium(calculatePremium(premiumData, true)));
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.error(err);
  }

  yield put(unsetGlobalLoading());
}

function* getPricingAfterLogin({ payload = {} }) {
  const {
    propertyId,
    employeeId,
    leadSourceTypeId,
    defaultBillingFrequency,
    userId,
    cid,
    customerId,
    leaseId,
    selectedProperty,
    startDate,
  } = yield select(
    ({
      getQuote: { property, employee, leadSourceType, user, clientId, customerId, leaseId, startDate },
      properties,
    }) => ({
      propertyId: property,
      employeeId: employee.id,
      leadSourceTypeId: leadSourceType,
      defaultBillingFrequency: properties[property].minimumBillingFrequency,
      userId: user,
      cid: clientId || properties[property].clientId,
      customerId,
      leaseId: leaseId,
      selectedProperty: properties[property],
      startDate,
    }),
  );
  yield put(setGlobalLoading("Almost done! We're preparing your quote now."));

  try {
    const response = yield call(Api.getPricingAfterLogin, {
      propertyId,
      employeeId,
      leadSourceTypeId,
      userId,
      cid,
      customerId,
      leaseId,
      entityId: payload.entityId,
      effectiveDate: format(startDate, 'MM-DD-YYYY'),
    });
    if (response.responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    } else if (!(response.responseCode === 200)) {
      throw new Error(response.message);
    }

    let pricingData = parsePricingData(response);
    let quoteData = parseQuoteData(response, defaultBillingFrequency);
    let {
      propertyDetails,
      totalPremium,
      showSubmitLater,
      availableProducts,
      isArrayEnrolmentEnabled,
      individualEndorsementTypeId,
      coverages: { products },
    } = response;

    if (selectedProperty.isGeneric) {
      propertyDetails = {
        ...propertyDetails,
        postalCode: selectedProperty.postalCode,
        city: selectedProperty.city,
        stateCode: selectedProperty.stateCode,
      };
    }

    let enhancementEndorsments = Object.values(products[1].endorsements).filter(
      (endorsment) => endorsment.isEnhancementEndorsement,
    );

    const { figaFee, figaeFee, isStatePolicyFeeApplicable, statePolicyFee, statePolicyMessage } = availableProducts[1];

    yield all([
      put({
        type: RECEIVE_PRICING,
        payload: pricingData,
      }),
      put({
        type: RECEIVE_AVAILABLE_PRODUCTS,
        payload: { availableProducts },
      }),
      put({
        type: INIT_QUOTE,
        payload: {
          products: quoteData.products,
          values: quoteData.values,
          billingFrequencies,
          location: propertyDetails,
          totalPremium: totalPremium,
          showSubmitLater: showSubmitLater,
          enhancementEndorsments,
          statePolicyFigaFee: figaFee,
          statePolicyFigaeFee: figaeFee,
          isStatePolicyFeeApplicable: isStatePolicyFeeApplicable,
          statePolicyFee: statePolicyFee,
          statePolicyMessage: statePolicyMessage,
          isArrayEnrolmentEnabled: isArrayEnrolmentEnabled,
          individualEndorsementTypeId: individualEndorsementTypeId,
        },
      }),
    ]);

    typeof payload.onComplete === 'function' && payload.onComplete();
    yield delay(1500);
    let premiumData = yield select(getPricingSelector);
    yield put(updatePremium(calculatePremium(premiumData)));
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.error(err);
  }

  yield put(unsetGlobalLoading());
}

function* watchGetPricing() {
  yield takeLatest(REQUEST_PRICING, getPricing);
}

function* watchGetPricingAfterLogin() {
  yield takeLatest(REQUEST_PRICING_AFTER_LOGIN, getPricingAfterLogin);
}

export const sagas = [watchGetPricing, watchGetPricingAfterLogin];
