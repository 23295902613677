/* eslint-disable */
import { createSelector } from 'reselect';
import { delay } from 'redux-saga';
import Api from 'api';
import leadSourceTypes from 'constants/leadSourceTypes';
import { select, call, put, takeLatest, all, race } from 'redux-saga/effects';
import { setGlobalLoading, unsetGlobalLoading, setGlobalLoadingWithDetails } from './globalLoading';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import { addError, removeError } from './errors';
import {
  validateToken,
  clearProcess,
  setToken,
  updateUser,
  updateIdTheftPolicyPaymentFailed,
  updateAllowRealTimePayment,
  updatePaymentTimeout,
} from './user';
import { setEmployee } from './getQuote';
import { setRedirectUrl } from './redirectUrl';
import { CLEAN_POLICIES } from 'redux/modules/policies';
import paymentTypes from 'constants/paymentTypes';

export const CLEAR_ENROLLMENT = 'ri/enrollment/CLEAR_ENROLLMENT';
export const SAVE_ENROLLMENT = 'ri/enrollment/SAVE_ENROLLMENT';
const REQUEST_SAVE_ENROLLMENT = 'ri/enrollment/REQUEST_SAVE_ENROLLMENT';
const REQUEST_VALIDATE_BLACKLIST_ACCOUNT = 'ri/enrollment/REQUEST_VALIDATE_BLACKLIST_ACCOUNT';
const REQUEST_SAVE_ENROLLMENT_AFTER_LOGIN = 'ri/enrollment/REQUEST_SAVE_ENROLLMENT_AFTER_LOGIN';
const REQUEST_VALIDATE_EMAIL_ADDRESS = 'ri/enrollment/REQUEST_VALIDATE_EMAIL_ADDRESS';
const UPDATE_EMAIL_VALID = 'ri/enrollment/UPDATE_EMAIL_VALID';
const REQUEST_VALIDATE_ROUTING_NUMBER = 'ri/enrollment/REQUEST_VALIDATE_ROUTING_NUMBER';
const UPDATE_ROUTING_NUMBER_ERROR = 'ri/enrollment/UPDATE_ROUTING_NUMBER_ERROR';
const UPDATE_ENROLL_POLICY_ERROR = 'ri/enrollment/UPDATE_ENROLL_POLICY_ERROR';
const UPDATE_HO4_PAYMENT_FAILED = 'ri/enrollment/UPDATE_HO4_PAYMENT_FAILED';
const UPDATE_ENROLLMENT_TIMEOUT = 'ri/enrollment/UPDATE_ENROLLMENT_TIMEOUT';

let customerInfo = (state) => state.enrollCustomerInfo;
let paymentInfo = (state) => state.enrollPaymentInfo;
let getQuote = (state) => state.getQuote;
let savedQuote = (state) => state.savedQuote;
let verifyAddress = (state) => state.verifyAddress;
let quote = (state) => state.quote;
let properties = (state) => state.properties;
let quoteValues = (state) => state.quoteValues;
let user = (state) => state.user;
let availableProducts = (state) => state.availableProducts;

export const enrollmentInfoSelector = createSelector(
  customerInfo,
  paymentInfo,
  getQuote,
  savedQuote,
  verifyAddress,
  quote,
  properties,
  quoteValues,
  user,
  availableProducts,
  (
    {
      emailAddress,
      nameFirst,
      nameLast,
      phoneNumber,
      password,
      streetLine,
      isSameMailingAddress,
      mailingStreetLine,
      mailingCity,
      mailingState,
      mailingPostalCode,
      accountNameFirst,
      accountNameLast,
      accountEmailAddress,
      accountPhoneNumber,
      mail_address,
      mail_suite,
      mail_state,
      mail_city,
      mail_zip,
      additionalInsureds,
      interestedParties,
      paperlessDocumentDelivery,
      ssnData,
    },
    {
      paymentTypeId,
      checkAccountTypeId,
      checkNameOnAccount,
      checkRoutingNumber,
      checkAccountNumber,
      ccNameOnCard,
      checkBankName,
      ccExpDateMonth,
      ccExpDateYear,
      ccCardNumberMasked,
      authToken,
      isSubmitLater,
    },
    {
      user,
      employee,
      leadSourceType,
      property,
      customerId,
      leaseId,
      applicantId,
      applicationId,
      originalInsurancePolicyId,
      originalAccountId,
      originalInsurancePolicyEndDate,
      cancelReasonTypeId,
    },
    {
      insertedQuote: {
        carrierClassificationTypeId,
        insuranceCarrierId,
        policyFrequencyId,
        effectiveDate,
        premiumAmount,
        adminFee,
        personalPremium,
        liabilityPremium,
        premiumTaxAmount,
        idTheftPremiumAmount,
        idTheftAdminFee,
        liabilityLimits,
        personalLimits,
        deductibleLimit,
        medicalPaymentLimitId,
        endorsements,
        isIdtheftSelected,
        insurancePolicyId,
        quoteId,
        quoteHistoryId,
        hurricaneDeductibleLimit,
        windstormConstructionCreditValue,
        buildingCodeGradingValue,
        automaticSprinklerCreditValue,
        statePolicyFee,
        nonHurricanePremium,
      },
    },
    { isAddressByPass },
    { location: { city, stateCode, postalCode }, figaPremium, figaePremium },
    properties,
    { defaultEnhancementEndorsement },
    { homebodyUserId, homebodyUuid },
    availableProducts
  ) => ({
    userId: parseInt(user) || null,
    employeeId: parseInt(employee.id) || null,
    leadSourceTypeId: parseInt(leadSourceType) || null,
    originalInsurancePolicyId: parseInt(originalInsurancePolicyId) || null,
    originalAccountId: parseInt(originalAccountId) || null,
    originalInsurancePolicyEndDate: originalInsurancePolicyEndDate || null,
    cancelReasonTypeId: cancelReasonTypeId || null,
    propertyId: parseInt(property) || null,
    cid: parseInt(properties[property].clientId) || null,
    property: properties[property],
    propertyName: properties[property].propertyName,
    isAllowedRealtimePayment: properties[property].isAllowedRealtimePayment,
    customerId: parseInt(customerId) || null,
    leaseId: parseInt(leaseId) || null,
    applicantId: parseInt(applicantId) || null,
    applicationId: parseInt(applicationId) || null,
    insurancePolicyId: parseInt(insurancePolicyId) || null,
    homebodyUserId: parseInt(homebodyUserId) || null,
    homebodyUuid: homebodyUuid || null,
    quoteId: parseInt(quoteId) || null,
    quoteHistoryId: parseInt(quoteHistoryId) || null,
    emailAddress,
    stateCode: stateCode || null,
    isIdtheftSelected: isIdtheftSelected,
    carrierClassificationTypeId: parseInt(carrierClassificationTypeId) || null,
    insuranceCarrierId: parseInt(insuranceCarrierId) || null,
    policyFrequencyId: parseInt(policyFrequencyId) || null,
    effectiveDate,
    premiumAmount: parseFloat(premiumAmount) || 0,
    adminFee: parseFloat(adminFee) || 0,
    personalPremium: parseFloat(personalPremium) || 0,
    liabilityPremium: parseFloat(liabilityPremium) || 0,
    premiumTaxAmount: parseFloat(premiumTaxAmount) || 0,
    idTheftPremiumAmount: parseFloat(idTheftPremiumAmount) || 0,
    idTheftAdminFee: parseFloat(idTheftAdminFee) || 0,
    idTheftPropertyFee:
      isIdtheftSelected && availableProducts[5] ? parseFloat(availableProducts[5].idTheftPropertyFee) : 0,
    liabilityLimits: {
      id: liabilityLimits.id,
      name: liabilityLimits.name,
      value: liabilityLimits.value,
    },
    personalLimits: {
      id: personalLimits.id,
      name: personalLimits.name,
      value: personalLimits.value,
    },
    deductibleLimit: {
      id: deductibleLimit.id,
      name: deductibleLimit.name,
      value: deductibleLimit.value,
    },
    hurricaneDeductibleLimit: hurricaneDeductibleLimit,
    windstormConstructionCreditValue: windstormConstructionCreditValue,
    buildingCodeGradingValue: buildingCodeGradingValue,
    automaticSprinklerCreditValue: automaticSprinklerCreditValue,
    statePolicyFee: parseFloat(statePolicyFee),
    figaPremium: parseFloat(figaPremium),
    figaePremium: parseFloat(figaePremium),
    nonHurricanePremium: nonHurricanePremium,
    medicalPaymentLimitId,
    enhancementEndorsementId: defaultEnhancementEndorsement,
    endorsements,
    customerDetails: {
      nameFirst: nameFirst || '',
      nameLast: nameLast || '',
      emailAddress: emailAddress || '',
      phoneNumber: formatPhoneNumber(phoneNumber),
      password: password || '',
      streetAddress: streetLine || '',
      city: city || '',
      stateCode: stateCode || null,
      postalCode: postalCode || '',
      mailingStreetAddress: isSameMailingAddress ? streetLine : mailingStreetLine,
      mailingState: isSameMailingAddress ? stateCode : mailingState,
      mailingCity: isSameMailingAddress ? city : mailingCity,
      mailingPostalCode: isSameMailingAddress ? postalCode || null : mailingPostalCode || null,
      ssnData: ssnData,
    },
    accountHolder: {
      nameFirst: accountNameFirst || '',
      nameLast: accountNameLast || '',
      emailAddress: accountEmailAddress || '',
      phoneNumber: formatPhoneNumber(accountPhoneNumber) || '',
      mailingStreetAddress: mail_address || '',
      mailingSuite: mail_suite || '',
      mailingState: mail_state || '',
      mailingCity: mail_city || '',
      mailingPostalCode: mail_zip || '',
    },
    additionalInsureds: additionalInsureds.map((additionalInsured) => ({
      nameFirst: additionalInsured.first,
      nameLast: additionalInsured.last,
    })),
    additionalInterests: interestedParties.map((interestedParty) => ({
      partyName: interestedParty.name,
      emailAddress: interestedParty.email,
      streetAddress: interestedParty.street,
      city: interestedParty.city,
      stateCode: interestedParty.state,
      postalCode: interestedParty.zip,
    })),
    paymentDetails: {
      isSubmitLater: isSubmitLater,
      paymentTypeId: paymentTypeId || '',
      billingStreetLine: streetLine || '',
      billingCity: city || '',
      billingStateCode: stateCode || '',
      billingPostalCode: postalCode || '',
      billtoEmailAddress: emailAddress || '',
      billtoPhoneNumber: formatPhoneNumber(phoneNumber) || '',
      billtoNameFirst: nameFirst || '',
      billtoNameLast: nameLast || '',
      accountName: checkNameOnAccount || '',
      checkBankName: checkBankName || '',
      accountTypeId: checkAccountTypeId || '',
      routingNumber: checkRoutingNumber || '',
      accountNumber: checkAccountNumber || '',
      confirmAccountNumber: checkAccountNumber || '',
      nameOnCard: ccNameOnCard || '',
      ccNumber: ccCardNumberMasked || '',
      expMonth: ccExpDateMonth ? parseInt(ccExpDateMonth) : '',
      expYear: ccExpDateYear ? new Date(`Jan 01, ${2000 + parseInt(ccExpDateYear)}`).getFullYear() : '',
      authToken: authToken || '',
    },
    isAddressByPass: isAddressByPass,
    paperlessDocumentDelivery: paperlessDocumentDelivery ?? null,
  })
);

const initialState = {
  isEmailUsed: false,
  routingNumberError: '',
  checkingRoutingNumber: false,
  enrollPolicyError: '',
  isHo4PaymentFailed: false,
  isEnrollmentTimeout: false,
};

// reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_ENROLLMENT:
      return { ...state, ...action.payload };
    case UPDATE_EMAIL_VALID:
      return {
        ...state,
        isEmailUsed: action.payload.isEmailUsed,
      };
    case REQUEST_VALIDATE_ROUTING_NUMBER:
      return {
        ...state,
        checkingRoutingNumber: true,
      };
    case UPDATE_ROUTING_NUMBER_ERROR:
      return {
        ...state,
        routingNumberError: action.payload.routingNumberError,
        checkingRoutingNumber: false,
      };
    case UPDATE_ENROLL_POLICY_ERROR:
      return {
        ...state,
        enrollPolicyError: action.payload.enrollPolicyError,
      };
    case UPDATE_HO4_PAYMENT_FAILED:
      return {
        ...state,
        isHo4PaymentFailed: action.payload.isHo4PaymentFailed,
      };
    case UPDATE_ENROLLMENT_TIMEOUT:
      return {
        ...state,
        isEnrollmentTimeout: action.payload.isEnrollmentTimeout,
      };
    case CLEAR_ENROLLMENT:
      return (state = initialState);
    default:
      return state;
  }
};

export const requestSaveEnrollment = (payload) => ({
  type: REQUEST_SAVE_ENROLLMENT,
  payload,
});

export const validateBlacklistAccount = (payload) => ({
  type: REQUEST_VALIDATE_BLACKLIST_ACCOUNT,
  payload,
});

export const requestSaveEnrollmentAfterLogin = (payload) => ({
  type: REQUEST_SAVE_ENROLLMENT_AFTER_LOGIN,
  payload,
});

export const requestCheckEmailAddress = (payload) => ({
  type: REQUEST_VALIDATE_EMAIL_ADDRESS,
  payload,
});

export const updateIsEmailUsed = (payload) => ({
  type: UPDATE_EMAIL_VALID,
  payload,
});

export const validateRoutingNumber = (payload) => ({
  type: REQUEST_VALIDATE_ROUTING_NUMBER,
  payload,
});

export const updateRoutingNumberError = (payload) => ({
  type: UPDATE_ROUTING_NUMBER_ERROR,
  payload,
});

export const updateEnrollPolicyError = (payload) => ({
  type: UPDATE_ENROLL_POLICY_ERROR,
  payload,
});

export const updateHo4PaymentFailed = (payload) => ({
  type: UPDATE_HO4_PAYMENT_FAILED,
  payload,
});

export const updateEnrollmentTimeout = (payload) => ({
  type: UPDATE_ENROLLMENT_TIMEOUT,
  payload,
});

function* enrollPolicy({ payload }) {
  yield put(setGlobalLoading('Give us a sec to get you covered.'));
  try {
    let policyData = yield select(enrollmentInfoSelector);

    const {
      isAllowedRealtimePayment,
      property,
      paymentDetails: { paymentTypeId, isSubmitLater },
    } = policyData;

    delete policyData.property;

    let response = {};
    if (isAllowedRealtimePayment && !isSubmitLater && paymentTypes[paymentTypeId]?.name != 'eCheck') {
      const { enrollmentResult, enrollmentTimeout } = yield race({
        enrollmentResult: call(Api.enrollPolicy, policyData),
        enrollmentTimeout: delay(60000),
      });

      response = enrollmentResult;

      //If enroll_policy API timeout will call the check payment status API
      if (enrollmentTimeout) {
        yield put(
          setGlobalLoadingWithDetails({
            message: 'Please do not refresh or close this page.',
            description:
              'We are still hard at work processing this request. The page will automatically refresh to a confirmation page.',
          })
        );

        const {
          insurancePolicyId,
          employeeId,
          userId,
          insuranceCarrierId,
          isIdtheftSelected,
          quoteId,
          quoteHistoryId,
        } = policyData;
        const { paymentResult, paymentTimeout } = yield race({
          paymentResult: call(Api.getPolicyPaymentStatus, {
            insurancePolicyId,
            employeeId,
            userId,
            insuranceCarrierId,
            isIdtheftSelected,
            quoteId,
            quoteHistoryId,
          }),
          paymentTimeout: delay(30000),
        });

        //If check payment status API timeout will return user to timeout page
        if (paymentTimeout || paymentResult.responseCode != 200) {
          yield put(updateEnrollmentTimeout({ isEnrollmentTimeout: true }));
          yield put(unsetGlobalLoading());
          return false;
        }

        response = paymentResult;
      }
    } else {
      response = yield call(Api.enrollPolicy, policyData);
    }

    let { responseCode, message = '', result } = response;

    if (responseCode === 200) {
      yield put({
        type: SAVE_ENROLLMENT,
        payload: {
          enrollment: policyData,
        },
      });

      let {
        quoteId,
        quoteHistoryId,
        insurancePolicyId,
        effectiveDate,
        carrierPolicyNumber,
        nameFirst,
        nameLast,
        entityId,
        homebodyUserId,
        token,
        emailAddress,
        phoneNumber,
        isIdTheftPaymentFailed = false,
        idTheftPaymentFailedReason = '',
        homebodyUserUuid,
        isPaymentTimeout
      } = result;

      const user = {
        insuranceQuoteId: quoteId,
        quoteHistoryId: quoteHistoryId,
        insurancePolicyId: insurancePolicyId,
        coverageStartDate: effectiveDate,
        carrierPolicyNumber: carrierPolicyNumber,
        id: policyData.userId,
        nameFirst,
        nameLast,
        entityId,
        homebodyUserId,
        token,
        email: emailAddress,
        employeeId: policyData.employeeId,
        leadSourceTypeId: policyData.leadSourceTypeId,
        role: 'user',
        phoneNumber,
        mailingAddress: {
          street: policyData.customerDetails.mailingStreetAddress,
          state: policyData.customerDetails.mailingState,
          city: policyData.customerDetails.mailingCity,
          zip: policyData.customerDetails.mailingPostalCode,
        },
        property,
        homebodyUserUuid: homebodyUserUuid
      };
      yield all([
        put(clearProcess()),
        put(updateUser(user)),
        put(setToken({ token })),
        put(
          setEmployee({
            value: {
              id: policyData.employeeId,
              name: '',
            },
          })
        ),
        put(setRedirectUrl('/enroll/enroll-success/')),
        delay(1500),
        yield typeof payload.onComplete === 'function' && payload.onComplete(),
        put(
          updateIdTheftPolicyPaymentFailed({
            isIdTheftPaymentFailed,
            idTheftPaymentFailedReason,
          })
        ),
        put(
          updateAllowRealTimePayment({
            isAllowedRealtimePayment:
              policyData.isAllowedRealtimePayment && Boolean(policyData.paymentDetails.paymentTypeId != 4),
          })
        ),
        put(updatePaymentTimeout({ isPaymentTimeout: isPaymentTimeout })),
      ]);
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    } else {
      yield put(
        updateHo4PaymentFailed({
          isHo4PaymentFailed: result?.isHo4PaymentFailed,
        })
      );
      yield put(updateEnrollPolicyError({ enrollPolicyError: message }));
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.log('error => ', err.toString());
    yield delay(3000);
  }
  yield put(unsetGlobalLoading());
}

function* validateAccount({ payload }) {
  try {
    const response = yield call(Api.validateBlacklistAccount, payload);

    if (response.responseCode === 200) {
      yield put(removeError('validateAccount'));
    } else if (response.responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    } else {
      yield put(
        addError({
          key: 'validateAccount',
          message: 'The account information you have provided is invalid.',
        })
      );
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    yield put(
      addError({
        key: 'validateAccount',
        message: 'The account information you have provided is invalid.',
      })
    );
  }
}

function* enrollPolicyAfterLogin({ payload }) {
  yield put(setGlobalLoading('Give us a sec to get you covered.'));
  try {
    let policyData = yield select(enrollmentInfoSelector);

    const {
      isAllowedRealtimePayment,
      property,
      paymentDetails: { paymentTypeId, isSubmitLater },
    } = policyData;

    delete policyData.property;
    policyData.entityId = payload.entityId;
    let leadSourceTypeId = policyData.leadSourceTypeId;
    policyData.leadSourceTypeId =
      [leadSourceTypes.TRASFER_POLICY, leadSourceTypes.HOMEBODY_APP ].includes(leadSourceTypeId) ? leadSourceTypeId : 9;

    let response = {};
    if (
      isAllowedRealtimePayment &&
      !isSubmitLater &&
      paymentTypes[paymentTypeId]?.name != 'eCheck'
    ) {
      const { enrollmentResult, enrollmentTimeout } = yield race({
        enrollmentResult: call(Api.enrollPolicyAfterLogin, policyData),
        enrollmentTimeout: delay(60000),
      });

      response = enrollmentResult;

      //If enroll_policy API timeout will call the check payment status API
      if (enrollmentTimeout) {
        yield put(
          setGlobalLoadingWithDetails({
            message: 'Please do not refresh or close this page.',
            description:
              'We are still hard at work processing this request. The page will automatically refresh to a confirmation page.',
          })
        );

        const {
          insurancePolicyId,
          entityId,
          employeeId,
          userId,
          insuranceCarrierId,
          isIdtheftSelected,
          quoteId,
          quoteHistoryId,
        } = policyData;
        const { paymentResult, paymentTimeout } = yield race({
          paymentResult: call(Api.getPolicyPaymentStatus, {
            insurancePolicyId,
            entityId,
            employeeId,
            userId,
            insuranceCarrierId,
            isIdtheftSelected,
            quoteId,
            quoteHistoryId,
          }),
          paymentTimeout: delay(30000),
        });

        //If check payment status API timeout will return user to timeout page
        if (paymentTimeout || paymentResult.responseCode != 200) {
          yield put(updateEnrollmentTimeout({ isEnrollmentTimeout: true }));
          yield put(unsetGlobalLoading());
          return false;
        }

        response = paymentResult;
      }
    } else {
      response = yield call(Api.enrollPolicyAfterLogin, policyData);
    }

    let { responseCode, message = '', result } = response;

    if (responseCode === 200) {
      yield put(setRedirectUrl('/addPolicy/enroll-success/'));
      let {
        quoteId,
        quoteHistoryId,
        insurancePolicyId,
        effectiveDate,
        carrierPolicyNumber,
        nameFirst,
        nameLast,
        entityId,
        token,
        emailAddress,
        phoneNumber,
        isIdTheftPaymentFailed = false,
        idTheftPaymentFailedReason = '',
        homebodyUserUuid,
        isPaymentTimeout
      } = result;

      const user = {
        insuranceQuoteId: quoteId,
        quoteHistoryId: quoteHistoryId,
        insurancePolicyId: insurancePolicyId,
        coverageStartDate: effectiveDate,
        carrierPolicyNumber: carrierPolicyNumber,
        id: policyData.userId,
        nameFirst,
        nameLast,
        entityId,
        token,
        email: emailAddress,
        employeeId: policyData.employeeId,
        leadSourceTypeId: policyData.leadSourceTypeId,
        role: 'user',
        phoneNumber,
        mailingAddress: {
          street: policyData.customerDetails.mailingStreetAddress,
          state: policyData.customerDetails.mailingState,
          city: policyData.customerDetails.mailingCity,
          zip: policyData.customerDetails.mailingPostalCode,
        },
        property,
        homebodyUserUuid: homebodyUserUuid
      };

      yield all([
        put({ type: CLEAN_POLICIES }),
        put(clearProcess()),
        put(updateUser(user)),
        put(setToken({ token })),
        yield typeof payload.onComplete === 'function' && payload.onComplete(),
        put(
          updateIdTheftPolicyPaymentFailed({
            isIdTheftPaymentFailed,
            idTheftPaymentFailedReason,
          })
        ),
        put(
          updateAllowRealTimePayment({
            isAllowedRealtimePayment:
              policyData.isAllowedRealtimePayment && Boolean(policyData.paymentDetails.paymentTypeId != 4),
          })
        ),
        put(updatePaymentTimeout({ isPaymentTimeout: isPaymentTimeout })),
      ]);
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    } else {
      yield put(
        updateHo4PaymentFailed({
          isHo4PaymentFailed: result?.isHo4PaymentFailed,
        })
      );
      yield put(updateEnrollPolicyError({ enrollPolicyError: message }));
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.log('error => ', err.toString());
  }
  yield put(unsetGlobalLoading());
}

function* validateEmailAddress({ payload: { emailAddress } }) {
  try {
    const response = yield call(Api.validateEmailAddress, { emailAddress });
    if (response.responseCode === 417) {
      yield put(
        updateIsEmailUsed({
          isEmailUsed: true,
        })
      );
    } else if (response.responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.log('error => ', err.toString());
  }
}

function* workerValidateRoutingNumber({ payload }) {
  try {
    const response = yield call(Api.validateRoutingNumber, payload);

    if (response.responseCode === 200) {
      yield put(updateRoutingNumberError({ routingNumberError: response.message }));
    } else if (response.responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    } else {
      yield put(updateRoutingNumberError({ routingNumberError: response.message }));
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    yield put(
      updateRoutingNumberError({
        routingNumberError: 'Routing number is not valid.',
      })
    );
  }
}

function* watchSaveEnrollment() {
  yield takeLatest(REQUEST_SAVE_ENROLLMENT, enrollPolicy);
}

function* watchValidateBlacklistAccount() {
  yield takeLatest(REQUEST_VALIDATE_BLACKLIST_ACCOUNT, validateAccount);
}

function* watchSaveEnrollmentAfterLogin() {
  yield takeLatest(REQUEST_SAVE_ENROLLMENT_AFTER_LOGIN, enrollPolicyAfterLogin);
}

function* watchValidateEmailAddress() {
  yield takeLatest(REQUEST_VALIDATE_EMAIL_ADDRESS, validateEmailAddress);
}

function* watchValidateRoutingNumber() {
  yield takeLatest(REQUEST_VALIDATE_ROUTING_NUMBER, workerValidateRoutingNumber);
}

export const sagas = [
  watchSaveEnrollment,
  watchValidateBlacklistAccount,
  watchSaveEnrollmentAfterLogin,
  watchValidateEmailAddress,
  watchValidateRoutingNumber,
];
