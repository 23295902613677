import Api from 'api';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import convertMoneyToInteger from 'utils/convertMoneyToInteger';
import moneyFormat from 'utils/moneyFormat';
import {
  PACKAGE_NAMES,
  ENHANCEMENT_ENDORSEMENTS,
  BILLING_FREQUENCIES_ID_MONTH,
  PACKAGES_STATIC_CONTENT,
} from 'constants/packages';

// action types
const ADD_PRODUCTS = 'ri/quotePackages/ADD_PRODUCTS';
export const CLEAN_QUOTE_PACKAGES = 'ri/quotePackages/CLEAN_QUOTE_PACKAGES';
const ADD_PACKAGE_DETAILS = 'ri/quotePackages/ADD_PACKAGES_DETAILS';
const CHANGE_PACKAGE = 'ri/quotePackages/CHANGE_PACKAGE';
const CHANGE_ID_THEFT_ENDORSEMENT_ID = 'ri/quotePackages/CHANGE_ID_THEFT_ENDORSEMENT_ID';
const UPDATE_IS_FROM_CUSTOMIZE_PACKAGE = 'ri/quotePackages/UPDATE_IS_FROM_CUSTOMIZE_PACKAGE';
const UPDATE_PACKAGE_NAME = 'ri/quotePackages/UPDATE_PACKAGE_NAME';

// reducer
const initialState = {
  availablePackages: [],
  propertyDetails: {},
  availableProducts: {},
  ho4Endorsements: [],
  idTheftEndorsements: [],
  enhancementEndorsments: [],
  liabilityLimits: [],
  deductibleLimits: [],
  personalContentLimits: [],
  packageName: PACKAGE_NAMES.BASIC.toLowerCase(),
  isFromCustomizePackage: false,
};

export const calculateOffsiteCoverage = (contentAmount, plan) => {
  const { offsiteCoveragePercent, minimumOffsiteCoverage } = plan;

  const intContentAmount = parseInt(contentAmount, 10);
  const intOffSiteCoverage = convertMoneyToInteger(minimumOffsiteCoverage);

  const offSiteCoverage = (intContentAmount * offsiteCoveragePercent) / 100;

  return offSiteCoverage > intOffSiteCoverage ? moneyFormat(offSiteCoverage) : minimumOffsiteCoverage;
};

export const getLimitById = (limits, limitId = null) => {
  if (limitId) {
    return limits?.find(({ id }) => Number(id) === Number(limitId));
  }
  return limits?.find(({ isSelected }) => isSelected);
};

export const getLimitByValue = (limits, limitValue) => {
  return limits?.find(({ value }) => Number(value) === Number(limitValue));
};

export const getMinimumLimit = (limits, defaultLimit, plan) => {
  let limitId = defaultLimit.id;
  let minimumLimitType = '';

  switch (limits.name) {
    case 'Deductible':
      minimumLimitType = 'minimumDeductible';
      break;
    case 'Personal':
      minimumLimitType = 'minimumPersonalContent';
      break;
    default:
      minimumLimitType = 'minimumLiability';
      break;
  }

  const minimumLimit = getLimitByValue(limits.options, plan[minimumLimitType]);

  if (
    minimumLimitType === 'minimumDeductible' &&
    minimumLimit &&
    Number(minimumLimit.value) < Number(defaultLimit.value)
  ) {
    limitId = minimumLimit.id;
  } else if (minimumLimit && Number(minimumLimit.value) > Number(defaultLimit.value)) {
    limitId = minimumLimit.id;
  }

  return limitId;
};

export const getEndorsement = (endorsments, endorsementId = null) => {
  if (endorsementId) {
    return endorsments.find(({ id }) => Number(id) === Number(endorsementId));
  }
  return endorsments.find(({ isSelected }) => isSelected);
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  const addPackageDetails = () => {
    const {
      coverages: { products = {} },
      propertyDetails = {},
      availableProducts = {},
    } = payload;

    // get ho4 and idTheft products
    const { 1: ho4Product = {}, 5: idTheftProduct = {} } = products;

    // get liability, personal content and deductable limits and endorsements
    const {
      carrierLimits: { liabilityLimits = {}, personalContentLimits = {}, deductibleLimits = {} },
      endorsements: ho4EndorsementsRekeyedById = {},
    } = ho4Product;
    const { endorsements: idTheftEndorsementsRekeyedById = {} } = idTheftProduct;

    // convert ho4 and idTheft endorsements object to array
    const ho4Endorsements = Object.values(ho4EndorsementsRekeyedById);
    const idTheftEndorsements = Object.values(idTheftEndorsementsRekeyedById);

    // filter enhancement endorsements
    const enhancementEndorsments = ho4Endorsements.filter((endorsement) => endorsement.isEnhancementEndorsement);

    const selectedEndorsements = [];
    ho4Endorsements.forEach(({ id, isSelected }) => {
      if (isSelected) {
        selectedEndorsements.push(id);
      }
    });

    const defaultIdTheftId = getEndorsement(idTheftEndorsements)?.id;
    const defaultLiabilityLimit = getLimitById(liabilityLimits?.options);
    const defaultPersonalContentLimit = getLimitById(personalContentLimits?.options);
    const defaultDeductibleLimit = getLimitById(deductibleLimits?.options);

    const defaultLimites = {
      idTheftEndorsementId: parseInt(defaultIdTheftId, 10) || 0,
      selectedEndorsements,
      billingFrequency: BILLING_FREQUENCIES_ID_MONTH,
    };

    const availablePackages = enhancementEndorsments.reduce((acc, { name, id }) => {
      let liabilityLimitId = defaultLiabilityLimit.id;
      let personalContentLimitId = defaultPersonalContentLimit.id;
      let deductibleLimitId = defaultDeductibleLimit.id;

      if (name === ENHANCEMENT_ENDORSEMENTS.SILVER) {
        const basicPlan = PACKAGES_STATIC_CONTENT[PACKAGE_NAMES.BASIC];

        liabilityLimitId = getMinimumLimit(liabilityLimits, defaultLiabilityLimit, basicPlan);
        personalContentLimitId = getMinimumLimit(personalContentLimits, defaultPersonalContentLimit, basicPlan);
        deductibleLimitId = getMinimumLimit(deductibleLimits, defaultDeductibleLimit, basicPlan);

        const minimumPersonalContentLimit = getLimitById(personalContentLimits.options, personalContentLimitId);

        return {
          ...acc,
          [PACKAGE_NAMES.BASIC]: {
            ...basicPlan,
            id,
            liabilityLimitId,
            personalContentLimitId,
            deductibleLimitId,
            offsiteCoverage: calculateOffsiteCoverage(minimumPersonalContentLimit.value, basicPlan),
            ...defaultLimites,
          },
        };
      }

      if (name === ENHANCEMENT_ENDORSEMENTS.GOLD) {
        const standardPlan = PACKAGES_STATIC_CONTENT[PACKAGE_NAMES.STANDARD];

        liabilityLimitId = getMinimumLimit(liabilityLimits, defaultLiabilityLimit, standardPlan);
        personalContentLimitId = getMinimumLimit(personalContentLimits, defaultPersonalContentLimit, standardPlan);
        deductibleLimitId = getMinimumLimit(deductibleLimits, defaultDeductibleLimit, standardPlan);

        const minimumPersonalContentLimit = getLimitById(personalContentLimits.options, personalContentLimitId);

        return {
          ...acc,
          [PACKAGE_NAMES.STANDARD]: {
            ...standardPlan,
            id,
            liabilityLimitId,
            personalContentLimitId,
            deductibleLimitId,
            offsiteCoverage: calculateOffsiteCoverage(minimumPersonalContentLimit.value, standardPlan),
            ...defaultLimites,
          },
        };
      }

      if (name === ENHANCEMENT_ENDORSEMENTS.PLATINUM) {
        const advancePlan = PACKAGES_STATIC_CONTENT[PACKAGE_NAMES.ADVANCED];

        liabilityLimitId = getMinimumLimit(liabilityLimits, defaultLiabilityLimit, advancePlan);
        personalContentLimitId = getMinimumLimit(personalContentLimits, defaultPersonalContentLimit, advancePlan);
        deductibleLimitId = getMinimumLimit(deductibleLimits, defaultDeductibleLimit, advancePlan);

        const minimumPersonalContentLimit = getLimitById(personalContentLimits.options, personalContentLimitId);

        return {
          ...acc,
          [PACKAGE_NAMES.ADVANCED]: {
            ...advancePlan,
            id,
            liabilityLimitId,
            personalContentLimitId,
            deductibleLimitId,
            offsiteCoverage: calculateOffsiteCoverage(minimumPersonalContentLimit.value, advancePlan),
            ...defaultLimites,
          },
        };
      }
      return acc;
    }, {});

    return {
      ...state,
      availablePackages: [
        availablePackages[PACKAGE_NAMES.BASIC],
        availablePackages[PACKAGE_NAMES.STANDARD],
        availablePackages[PACKAGE_NAMES.ADVANCED],
      ],
      propertyDetails,
      availableProducts,
      ho4Endorsements,
      idTheftEndorsements,
      enhancementEndorsments,
      liabilityLimits: liabilityLimits.options,
      personalContentLimits: personalContentLimits.options,
      deductibleLimits: deductibleLimits.options,
    };
  };

  const changePackage = () => {
    const { availablePackages } = state;

    const filterPackages = availablePackages.filter((pkg) => pkg.name !== payload.name);

    return {
      ...state,
      availablePackages: [
        ...filterPackages,
        {
          ...payload,
        },
      ],
    };
  };

  const changeIdTheftEndorsementId = () => {
    const { availablePackages } = state;
    const { pkgName, idTheftEndorsementId } = payload;

    availablePackages.forEach((pkg) => {
      // Making shadow copy to resolve no-param-reassign eslint
      const dummyPackage = pkg;
      if (dummyPackage.name === pkgName) {
        dummyPackage.idTheftEndorsementId = idTheftEndorsementId;
      }
    });
    return {
      ...state,
      ...availablePackages,
    };
  };

  switch (type) {
    case ADD_PACKAGE_DETAILS:
      return addPackageDetails();
    case CHANGE_PACKAGE:
      return changePackage();
    case CHANGE_ID_THEFT_ENDORSEMENT_ID:
      return changeIdTheftEndorsementId();
    case CLEAN_QUOTE_PACKAGES:
      return initialState;
    case UPDATE_IS_FROM_CUSTOMIZE_PACKAGE:
      return {
        ...state,
        isFromCustomizePackage: payload,
      };
    case UPDATE_PACKAGE_NAME:
      return {
        ...state,
        packageName: payload.toLowerCase(),
      };
    default:
      return state;
  }
};

// action creators
export const addProducts = (payload) => ({
  type: ADD_PRODUCTS,
  payload,
});

export const addPackageDetails = (payload) => ({
  type: ADD_PACKAGE_DETAILS,
  payload,
});

export const changePackage = (payload) => ({
  type: CHANGE_PACKAGE,
  payload,
});

export const changeIdTheftEndorsementId = (payload) => ({
  type: CHANGE_ID_THEFT_ENDORSEMENT_ID,
  payload,
});

export const updateIsFromCustomizePackage = (payload) => ({
  type: UPDATE_IS_FROM_CUSTOMIZE_PACKAGE,
  payload,
});

export const updatePackageName = (payload) => ({
  type: UPDATE_PACKAGE_NAME,
  payload,
});

// sagas
function* addProductsSaga(action) {
  try {
    const [ho4Product, idTheftProduct] = yield all([
      call(Api.addHo4Product, action.payload[1]),
      call(Api.addIdTheftProduct, action.payload[5]),
    ]);
    yield put(addProducts([ho4Product, idTheftProduct]));
  } catch (error) {
    console.error('Error adding products:', error);
  }
}

// watchers
function* watchAddProducts() {
  yield takeLatest(ADD_PRODUCTS, addProductsSaga);
}

// export default function* rootSaga() {
export const sagas = [watchAddProducts];
